import { useEffect, createContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { primaryBlue, hoverBlue } from "./shared";
import {
  Authenticator,
  ThemeProvider,
  useTheme,
  View,
  Image,
  Heading,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Button } from "@cloudscape-design/components";

export const UserContext = createContext({});

export default function SignIn() {
  const { tokens } = useTheme();
  const { authStatus } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  const theme = {
    name: "Cloudscape",
    tokens: {
      fonts: {
        default: {
          variable: { value: "Open Sans, sans-serif" },
          static: { value: "Open Sans, sans-serif" },
        },
        weights: tokens.fontWeights.bold,
      },
      colors: {
        brand: {
          primary: {
            10: tokens.colors.blue["20"], // buttons & links on focus colour
            80: primaryBlue, // signin button active colour
            90: hoverBlue, // signin button hover colour
            100: primaryBlue, // field focus border colour
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: primaryBlue,
              },
            },
            _hover: {
              color: {
                value: hoverBlue,
              },
            },
            _active: {
              color: {
                value: primaryBlue,
              },
            },
          },
        },
      },
    },
  };

  const formFields = {
    signUp: {
      given_name: {
        order: 1,
      },
      family_name: { order: 2 },
      email: { order: 3 },
    },
  };

  const components = {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.xxl}>
          <Image
            alt="Easypermits Logo"
            src={process.env.PUBLIC_URL + "easypermits-logo-black.svg"}
          />
        </View>
      );
    },
    SignIn: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={5}
          >
            Sign in to your Agent or Developer account
          </Heading>
        );
      },
    },
    SignUp: {
      Header() {
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={5}
          >
            Create a new Agent or Developer account
          </Heading>
        );
      },
    },
  };

  useEffect(() => {
    if (authStatus === "authenticated") {
      if (from === "/") {
        navigate("/dashboard", { replace: true });
      } else {
        navigate(from, { replace: true }); // go to page user was previously at
      }
    }
  }, [authStatus, navigate, from]);

  return (
    <>
      {/* Amplify Login form with customizations */}
      <ThemeProvider theme={theme}>
        <Authenticator formFields={formFields} components={components} />
      </ThemeProvider>

      <div style={{ textAlign: "center", padding: 30 }}>
        {authStatus === "configuring"
          ? "Loading..."
          : authStatus === "unauthenticated" && (
              <Button href="/" variant="link">
                Back to Homepage
              </Button>
            )}
      </div>
    </>
  );
}
