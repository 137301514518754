import { useEffect, useState } from "react";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Button,
  Container,
  Box,
  Icon,
  ColumnLayout,
  Popover,
  Link,
  Grid,
  ExpandableSection,
  Toggle,
} from "@cloudscape-design/components";
import { Popover as MuiPopover } from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import PlaceIcon from "@mui/icons-material/Place";
import {
  lightGray,
  primaryBlue,
  warningOrange,
  denmarkMunicipalityList,
  Divider,
} from "../../shared";
import BluePin from "../../assets/blue-pin.png";
import RedPin from "../../assets/red-pin.png";
import RedPinRaised from "../../assets/red-pin-raised.png";
import GreenPin from "../../assets/green-pin.png";
import WindfarmBackdrop from "../../assets/Windfarm-backdrop.png";
import WindfarmIcon from "../../assets/windfarm-icon.png";
import HousesIcon from "../../assets/houses.png";
import MapExampleScreenshot from "../../assets/map-use-example.png";
import CommunityNavigationBar from "./CommunityNavigationBar";
import NavigationBar from "../NavigationBar";
// AWS Geo Maps
import { MapView, LocationSearch } from "@aws-amplify/ui-react-geo";
import "@aws-amplify/ui-react-geo/styles.css";
import { Marker, Popup, NavigationControl } from "react-map-gl";

export default function WindfarmLocationsMap() {
  const { t } = useTranslation();
  const { user } = useAuthenticator((context) => [context.route]);
  const [showProjectDetails, setShowProjectDetails] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProjectIndex, setSelectedProjectIndex] = useState(null);
  // Geo Maps
  const [inPermitMarker, setInPermitMarker] = useState(true);
  const [appealPhaseMarker, setAppealPhaseMarker] = useState(true);
  const [appealRaisedMarker, setAppealRaisedMarker] = useState(true);
  const [afterAppealMarker, setAfterAppealMarker] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [{ latitude, longitude }] = useState({
    latitude: 56.1386,
    longitude: 10.4,
  });

  const handleMarkerClick = ({ originalEvent }, pid) => {
    const index = projects?.findIndex((p) => p.id === pid);
    setSelectedProjectIndex(index);
    originalEvent.stopPropagation();
    setShowPopup(true);
    setShowProjectDetails(true);
  };

  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };

  const getDocumentExt = (d) => d.split(".")[d.split(".").length - 1];

  const downloadDocument = async (pid, did, name) => {
    const doc = await Storage.list(`projects/${pid}/documents/${did}`);
    let key = "";
    if (doc?.results?.length > 0) {
      key = doc?.results?.sort((a, b) => {
        return new Date(b.lastModified) - new Date(a.lastModified);
      })[0]?.key;
    }
    const signedURL = await Storage.get(key, { download: true });
    downloadBlob(signedURL.Body, `${name}.${getDocumentExt(key)}`);
  };

  const getProjects = async () => {
    const query = /* GraphQL */ `
      query ListProjects(
        $filter: ModelProjectFilterInput
        $limit: Int
        $nextToken: String
      ) {
        listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
          items {
            id
            name
            description
            longitude
            latitude
            additionalInfo
            contact_link
            contact_organization
            benefits
            projectType
            isInRevision
            provides_households
            size
            capacity
            turbines
            documents(
              filter: { status: { eq: d_done }, publishable: { eq: true } }
            ) {
              items {
                id
                name
                stage
              }
            }
            height
            diameter
            startDate
            endDate
            contact_organization
            rotorDiameter
            municipalities
            preAgreementLandowners
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            relationNeighbours
            stage
            projectClosed
          }
        }
      }
    `;
    const variables = {
      filter: {
        stage: {
          ne: "a_preplanning",
        },
      },
      limit: 5000,
    };
    try {
      const gql = graphqlOperation(query, variables);
      const res = await API.graphql({
        ...gql,
        authMode: user ? "AMAZON_COGNITO_USER_POOLS" : "AWS_IAM",
      });
      if (res?.data?.listProjects?.items.length) {
        setProjects(res?.data?.listProjects?.items);
      }
    } catch (error) {
      console.log(`Could not get data ${JSON.stringify(error)}`);
    }
  };

  const validateBenefitsInput = (string) => {
    // check if message contains a url
    if (
      new RegExp(
        "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
      ).test(string)
    ) {
      const urlRegExp =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
      const url = string.match(urlRegExp); // extract the url from the message
      const text = string.replace(urlRegExp, ""); // remove the url text so we can display the url as Link instead

      return (
        <>
          {text}{" "}
          <Link
            href={url[0]}
            target="_blank"
            rel="noreferrer"
            sx={{
              marginLeft: 1,
              textDecoration: "underline",
              color: "white",
            }}
          >
            {url}
          </Link>
        </>
      );
    } else return string;
  };

  const [isPageLoading, setPageLoading] = useState(true);

  useEffect(() => {
    document.title = "EasyPermits® Map";
    if (user) {
      getProjects();
    }
  }, [user]);

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <>
      {
        // Dynamically render nav bar depending on whether user is logged in
        user ? <NavigationBar /> : <CommunityNavigationBar />
      }
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={
          <ContentLayout
            header={
              <SpaceBetween size="xxl">
                <div />
                <Header
                  variant="h1"
                  description={
                    <h3 style={{ color: "#e9ebed" }}>
                      {t(
                        "Search this page to find more information on projects in the permitting process in the neighbourhood"
                      )}
                    </h3>
                  }
                  actions={
                    <Button
                      variant="primary"
                      href="/faqs"
                      iconAlign="left"
                      iconName="status-info"
                    >
                      {t("Frequently Asked Questions")}
                    </Button>
                  }
                >
                  {t("Search Wind Projects on the Map")}
                </Header>
                <div />
              </SpaceBetween>
            }
          >
            <Container
              fitHeight
              media={{
                position: "side",
                content: (
                  // For all Map features, see: https://visgl.github.io/react-map-gl/
                  <MapView
                    style={{ width: 900 }}
                    initialViewState={{
                      latitude: latitude,
                      longitude: longitude,
                      zoom: 7,
                    }}
                  >
                    <NavigationControl position="bottom-left" />
                    <LocationSearch
                      position="top-left"
                      proximity={{ latitude, longitude }}
                    />
                    {/* Render In Permit project pins on Map */}
                    {projects
                      .filter(
                        (p) => p?.stage[0] !== "f" && p?.projectClosed !== true
                      )
                      .map(
                        (project, index) =>
                          project?.longitude &&
                          project?.latitude &&
                          inPermitMarker && (
                            <Marker
                              anchor="top"
                              scale={3}
                              latitude={project?.latitude}
                              longitude={project?.longitude}
                              onClick={(event) =>
                                handleMarkerClick(event, project?.id)
                              }
                            >
                              <img
                                alt="blue pin"
                                src={BluePin}
                                style={{ width: "50%" }}
                              />
                            </Marker>
                          )
                      )}
                    {/* Render Appeal Phase project pins on Map */}
                    {projects
                      .filter(
                        (p) =>
                          p?.stage[0] === "f" &&
                          !p?.isInRevision &&
                          p?.e_approval_closed &&
                          // show projects in Appeal phase if project is within 4 weeks old (= 2419200066 milliseconds)
                          new Date() - new Date(p?.e_approval_closed) <=
                            2419200066
                      )
                      .map(
                        (project, index) =>
                          project?.longitude &&
                          project?.latitude &&
                          appealPhaseMarker && (
                            <Marker
                              anchor="top"
                              scale={3}
                              latitude={project?.latitude}
                              longitude={project?.longitude}
                              onClick={(event) =>
                                handleMarkerClick(event, project?.id)
                              }
                            >
                              <img
                                alt="blue pin"
                                src={RedPin}
                                style={{ width: "50%" }}
                              />
                            </Marker>
                          )
                      )}
                    {/* Render Appeal Raised project pins on Map */}
                    {projects
                      .filter(
                        (p) => p?.stage[0] === "f" && p?.isInRevision === true
                      )
                      .map(
                        (project, index) =>
                          project?.longitude &&
                          project?.latitude &&
                          appealRaisedMarker && (
                            <Marker
                              anchor="top"
                              scale={3}
                              latitude={project?.latitude}
                              longitude={project?.longitude}
                              onClick={(event) =>
                                handleMarkerClick(event, project?.id)
                              }
                            >
                              <img
                                alt="blue pin"
                                src={RedPinRaised}
                                style={{ width: "50%" }}
                              />
                            </Marker>
                          )
                      )}
                    {/* Render Planned project pins on Map */}
                    {projects
                      .filter(
                        (p) =>
                          p?.stage[0] === "f" &&
                          !p?.isInRevision &&
                          p?.e_approval_closed &&
                          // show projects in Planned phase if project is more than 6 months old (= 15778800000 milliseconds)
                          new Date() - new Date(p?.e_approval_closed) >
                            15778800000
                      )
                      .map(
                        (project, index) =>
                          project?.longitude &&
                          project?.latitude &&
                          afterAppealMarker && (
                            <Marker
                              anchor="top"
                              scale={3}
                              latitude={project?.latitude}
                              longitude={project?.longitude}
                              onClick={(event) =>
                                handleMarkerClick(event, project?.id)
                              }
                            >
                              <img
                                alt="blue pin"
                                src={GreenPin}
                                style={{ width: "50%" }}
                              />
                            </Marker>
                          )
                      )}

                    {showPopup && (
                      <Popup
                        latitude={projects[selectedProjectIndex]?.latitude}
                        longitude={projects[selectedProjectIndex]?.longitude}
                        offset={{ bottom: [0, -40] }}
                        onClose={() => setShowPopup(false)}
                        style={{ borderRadius: "50px !important" }}
                      >
                        <img
                          alt="windfarm-popup-card"
                          src={WindfarmBackdrop}
                          style={{ width: "100%", borderRadius: 5 }}
                        />
                        <h2 level={2}>
                          {projects?.length &&
                            projects[selectedProjectIndex]?.name}
                        </h2>
                        <span style={{ fontSize: "1.1em" }}>
                          {t(
                            projects?.length &&
                              projects[selectedProjectIndex]?.projectType
                          )}{" "}
                          {t("by")}{" "}
                          {projects?.length &&
                            projects[selectedProjectIndex]
                              ?.contact_organization}
                        </span>
                      </Popup>
                    )}
                  </MapView>
                ),
              }}
            >
              <SpaceBetween direction="vertical" size="s">
                {!showProjectDetails ? (
                  <>
                    <Box>
                      <div style={{ display: "flex" }}>
                        <PlaceIcon sx={{ mt: 1.5, mr: 0.5 }} />
                        <h3>{t("Pins on the map")}</h3>
                        <div style={{ marginTop: 15, marginLeft: 10 }}>
                          <Popover
                            position="right"
                            size="small"
                            triggerType="custom"
                            content={t(
                              "The projects are still in the process of approval, which does not guarantee their full implementation."
                            )}
                          >
                            <Icon name="status-info" variant="link" />
                          </Popover>
                        </div>
                      </div>
                      <br />
                      <SpaceBetween size="xl">
                        <MapPins
                          title={t("In Permit Process")}
                          projects={projects.filter(
                            (p) => p?.stage[0] !== "f" && !p?.projectClosed
                          )}
                          pinImage={BluePin}
                          checked={inPermitMarker}
                          setChecked={setInPermitMarker}
                        />
                        <MapPins
                          title={t("Appeal Phase")}
                          projects={projects.filter(
                            (p) =>
                              p?.stage[0] === "f" &&
                              !p?.isInRevision &&
                              p?.e_approval_closed &&
                              // show projects in Appeal phase if project is within 4 weeks old (= 2419200066 milliseconds)
                              new Date() - new Date(p?.e_approval_closed) <=
                                2419200066.23
                          )}
                          pinImage={RedPin}
                          checked={appealPhaseMarker}
                          setChecked={setAppealPhaseMarker}
                        />
                        <MapPins
                          title={t("Appeal Raised")}
                          projects={projects.filter(
                            (p) =>
                              p?.stage[0] === "f" && p?.isInRevision === true
                          )}
                          pinImage={RedPinRaised}
                          checked={appealRaisedMarker}
                          setChecked={setAppealRaisedMarker}
                        />
                        <MapPins
                          title={t("Planned")}
                          projects={projects.filter(
                            (p) =>
                              p?.stage[0] === "f" &&
                              !p?.isInRevision &&
                              p?.e_approval_closed &&
                              // show projects in Planned phase if project is more than 6 months old (= 15778800000 milliseconds)
                              new Date() - new Date(p?.e_approval_closed) >
                                15778800000
                          )}
                          pinImage={GreenPin}
                          checked={afterAppealMarker}
                          setChecked={setAfterAppealMarker}
                        />
                      </SpaceBetween>
                    </Box>
                    <Divider />
                    <Box>
                      <ExpandableSection
                        variant="footer"
                        headerText={t("How to use this page?")}
                      >
                        <ol>
                          <SpaceBetween size="s">
                            <li>
                              {t(
                                "Search for an address or postal code (including country) to see projects in the permitting process."
                              )}
                            </li>
                            <li>
                              {t(
                                "The red markers indicate projects in the permitting process."
                              )}
                            </li>
                            <li>
                              {t(
                                "Click on the red marker to see more details of that project in the permitting process."
                              )}
                            </li>
                          </SpaceBetween>
                        </ol>
                      </ExpandableSection>
                      <ExpandableSection
                        variant="footer"
                        headerText={t("Using the map search feature")}
                      >
                        <ol>
                          <SpaceBetween size="s">
                            <li>
                              {t(
                                "Enter an address, postcode or country in the search bar."
                              )}
                            </li>
                            <li>
                              {t(
                                "Select your result in the drop-down list. The map will automatically fly and zoom into that location."
                              )}
                            </li>
                            <li>
                              {t(
                                "You should still see the selected result under the seach bar. Click on it again. The map should then automatically zoom out."
                              )}
                            </li>
                            <img
                              alt="maps use example screenshot"
                              src={MapExampleScreenshot}
                              style={{
                                float: "left",
                                height: "70%",
                                width: "70%",
                                marginLeft: 20,
                                border: "3px solid gray",
                                borderRadius: 17,
                              }}
                            />
                            <li>
                              {t(
                                "If the map does not zoom out far enough, please use the +/- navigation tool at the bottom left corner or mouse scroll up/down to control the zoom"
                              )}
                            </li>
                          </SpaceBetween>
                        </ol>
                      </ExpandableSection>
                    </Box>
                  </>
                ) : (
                  // Show project details on the right when user selects a project pin on the map
                  <Box>
                    <Box>
                      <Box float="right">
                        <Button
                          variant="icon"
                          iconName="close"
                          size="medium"
                          onClick={() => {
                            setShowProjectDetails(false);
                            setShowPopup(false);
                          }}
                        />
                      </Box>
                      <br />
                      <br />
                      <div
                        style={{
                          display: "flex",
                          height: 150,
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            backgroundImage: `url(${WindfarmBackdrop})`,
                            backgroundSize: "100%",
                            backgroundPosition: "bottom 100px",
                            backgroundRepeat: "no-repeat",
                            borderRadius: 7,
                            textAlign: "center",
                            paddingTop: 20,
                          }}
                        >
                          <b
                            style={{
                              fontSize: "1.2em",
                              color: "white",
                            }}
                          >
                            {projects?.length &&
                              projects[selectedProjectIndex]?.name}
                            <br />
                            {t(
                              projects?.length &&
                                projects[selectedProjectIndex]?.projectType
                            )}{" "}
                            {t("by")}{" "}
                            {projects?.length &&
                              projects[selectedProjectIndex]
                                ?.contact_organization}
                          </b>
                        </div>
                      </div>
                    </Box>
                    <Box>
                      <SpaceBetween>
                        <Box>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              paddingTop: 20,
                              paddingBottom: 10,
                            }}
                          >
                            <div>
                              <b>{t("Permit Start Date")}</b>
                              <br />
                              {(projects?.length &&
                                new Date(
                                  projects[selectedProjectIndex]?.startDate
                                ).toLocaleDateString()) || (
                                <i style={{ color: "gray" }}>
                                  {t("StartDateNotSet")}
                                </i>
                              )}
                            </div>
                            {projects[selectedProjectIndex]
                              ?.e_approval_closed && (
                              <div
                                style={{ textAlign: "right", float: "right" }}
                              >
                                <b>{t("Permit End Date")}</b>
                                <br />
                                {(
                                  projects?.length &&
                                  new Date(
                                    projects[
                                      selectedProjectIndex
                                    ]?.e_approval_closed
                                  )
                                ).toLocaleDateString() || (
                                  <i style={{ color: "gray" }}>
                                    {t("End date not set yet")}
                                  </i>
                                )}
                              </div>
                            )}
                          </div>
                          {projects?.length &&
                            (projects[selectedProjectIndex]?.startDate ||
                              projects[selectedProjectIndex]?.endDate) && (
                              <ProjectTimeline
                                project={projects[selectedProjectIndex]}
                              />
                            )}
                          <i style={{ color: "gray", fontSize: "0.9em" }}>
                            {t("Hover over each stage to see more details")}
                          </i>
                        </Box>
                        <br />
                        <ColumnLayout columns={2}>
                          <div>
                            <b>{t("Generalities")}</b>
                            <ul>
                              <li>{t("Country")}: Denmark</li>
                              <li>
                                {t("Municipality")}:{" "}
                                {projects?.length &&
                                  projects[
                                    selectedProjectIndex
                                  ]?.municipalities.map((municipality) =>
                                    denmarkMunicipalityList.map(
                                      (item) =>
                                        item.value === municipality &&
                                        `${item.label} `
                                    )
                                  )}
                              </li>
                              <li>
                                {t("Organisation")}:{" "}
                                {projects?.length &&
                                  projects[selectedProjectIndex]
                                    ?.contact_organization}
                              </li>
                            </ul>
                          </div>
                        </ColumnLayout>
                        <ColumnLayout columns={2}>
                          <div>
                            <b>{`${t("details").charAt(0).toUpperCase()}${t(
                              "details"
                            ).slice(1)}`}</b>
                            <ul>
                              <li>
                                {t("Overall Capacity")}:{" "}
                                {projects?.length &&
                                  projects[selectedProjectIndex]?.capacity}{" "}
                                MW
                              </li>
                              <li>
                                {t("Number of Turbines")}:{" "}
                                {projects?.length &&
                                  projects[selectedProjectIndex]?.turbines}
                              </li>
                              <li>
                                {t("Expected Height")}:{" "}
                                {projects?.length &&
                                  projects[selectedProjectIndex]?.height +
                                    " " +
                                    t("meters")}
                              </li>
                              <li>
                                {t("Expected Rotor Diameter")}:{" "}
                                {projects?.length &&
                                  projects[selectedProjectIndex]?.diameter +
                                    " " +
                                    t("meters")}
                              </li>
                              {projects?.length &&
                                projects[selectedProjectIndex]?.size &&
                                projects[selectedProjectIndex]?.projectType !==
                                  "wind" && (
                                  <li>
                                    {t("Size")}:{" "}
                                    {projects[selectedProjectIndex]?.size +
                                      " " +
                                      t("hectares")}
                                  </li>
                                )}
                            </ul>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                            }}
                          >
                            <div>
                              <b>Type</b>
                              <div
                                style={{
                                  display: "flex",
                                }}
                              >
                                <p>
                                  {t(
                                    projects?.length &&
                                      projects[selectedProjectIndex]
                                        ?.projectType
                                  )}
                                </p>
                                <img
                                  alt="windfarm icon"
                                  src={WindfarmIcon}
                                  style={{
                                    width: "20%",
                                    height: "25%",
                                    marginTop: -15,
                                    marginBottom: 15,
                                    marginLeft: 20,
                                    marginRight: 40,
                                  }}
                                />
                              </div>
                            </div>
                            {projects?.length &&
                              projects[selectedProjectIndex]
                                ?.provides_households && (
                                <div>
                                  <b>{t("It will supply")}</b>
                                  <div
                                    style={{
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{ marginTop: 15, float: "left" }}
                                    >
                                      <b
                                        style={{
                                          color: primaryBlue,
                                          fontSize: 20,
                                          marginRight: 10,
                                        }}
                                      >
                                        {
                                          projects[selectedProjectIndex]
                                            ?.provides_households
                                        }
                                      </b>
                                      <span>{t("households")}</span>
                                    </div>
                                    <img
                                      alt="houses icon"
                                      src={HousesIcon}
                                      style={{
                                        float: "right",
                                        height: "30%",
                                        width: "30%",
                                        marginTop: 0,
                                        marginLeft: 20,
                                      }}
                                    />
                                  </div>
                                </div>
                              )}
                          </div>
                        </ColumnLayout>
                        <br />
                        <b>{t("Project Overview")}</b>
                        {projects?.length &&
                          projects[selectedProjectIndex]?.description}
                        <br />
                        {projects[selectedProjectIndex]?.benefits && (
                          <p>
                            <b>{t("Benefits for the Community")}</b>
                            <br />
                            {validateBenefitsInput(
                              projects[selectedProjectIndex]?.benefits
                            )}
                          </p>
                        )}
                        {projects[selectedProjectIndex]?.contact_link && (
                          <p>
                            <b>{t("Developer Website")}</b>
                            <br />
                            <a
                              href={
                                projects[selectedProjectIndex]?.contact_link
                              }
                            >
                              {projects[selectedProjectIndex]
                                ?.contact_organization ||
                                projects[selectedProjectIndex]?.contact_link}
                            </a>
                          </p>
                        )}
                        {projects?.length &&
                          projects[selectedProjectIndex]?.municipalities.map(
                            (municipality) =>
                              denmarkMunicipalityList.map(
                                (item) =>
                                  item.value === municipality && (
                                    <>
                                      <b>
                                        {t(
                                          "More information on municipality webpage"
                                        )}
                                      </b>
                                      <Link external href={item.website}>
                                        {item.website}
                                      </Link>
                                      <br />
                                      {item?.feedback && (
                                        <>
                                          <b>
                                            {t(
                                              "To get your questions answered about the project"
                                            )}
                                          </b>
                                          <Link external href={item.feedback}>
                                            {item.feedback}
                                          </Link>
                                        </>
                                      )}
                                    </>
                                  )
                              )
                          )}
                        <br />
                        {projects[selectedProjectIndex]?.documents?.items
                          ?.length > 0 && <b>{t("Documents")}</b>}
                        {projects[selectedProjectIndex]?.documents?.items
                          ?.length > 0 &&
                          projects[selectedProjectIndex]?.documents?.items
                            ?.filter(
                              (d) =>
                                projects[selectedProjectIndex][
                                  `${d.stage}_closed`
                                ]
                            )
                            .map((document) => (
                              <Link
                                onFollow={(e) => {
                                  e.preventDefault();
                                  downloadDocument(
                                    projects[selectedProjectIndex]?.id,
                                    document.id,
                                    `${
                                      projects[selectedProjectIndex]?.name
                                    } - ${t(document.name)}`
                                  );
                                }}
                              >
                                {t(document.name)}
                              </Link>
                            ))}
                        <br />
                        <SpaceBetween direction="horizontal" size="s">
                          <Icon name="status-info" />
                          <span
                            style={{
                              marginTop: 30,
                              fontSize: "0.9em",
                            }}
                          >
                            {t(
                              "All the above information is provided by the project developer"
                            )}
                          </span>
                        </SpaceBetween>
                      </SpaceBetween>
                    </Box>
                  </Box>
                )}
              </SpaceBetween>
            </Container>
          </ContentLayout>
        }
      />
    </>
  );
}

const MapPins = ({ title, projects, pinImage, checked, setChecked }) => {
  const { t } = useTranslation();

  return (
    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 2 }, { colspan: 4 }]}>
      <div style={{ display: "flex" }}>
        <img
          alt="marker"
          src={pinImage}
          style={{
            width: "13%",
            marginTop: -7,
            marginRight: 10,
          }}
        />
        {t(title)}
      </div>
      <b
        style={{
          color: primaryBlue,
          fontSize: "1.8em",
          marginRight: 10,
        }}
      >
        {projects.length}
      </b>

      <Toggle
        checked={checked}
        onChange={({ detail }) => setChecked(detail.checked)}
      >
        <span style={{ fontSize: "0.75em" }}>{t("Show/hide")}</span>
      </Toggle>
    </Grid>
  );
};

const ProjectTimeline = ({ project }) => {
  const { t } = useTranslation();

  // pre-planning
  const [pp_anchorEl, set_PP_AnchorEl] = useState(null);
  const open_PP_Popover = Boolean(pp_anchorEl);

  // initial public consultation
  const [ipc_anchorEl, set_IPC_AnchorEl] = useState(null);
  const open_IPC_Popover = Boolean(ipc_anchorEl);

  // planning process
  const [process_anchorEl, set_PROCESS_AnchorEl] = useState(null);
  const open_PROCESS_Popover = Boolean(process_anchorEl);

  // public consultation
  const [pc_anchorEl, set_PC_AnchorEl] = useState(null);
  const open_PC_Popover = Boolean(pc_anchorEl);

  // final approval
  const [fa_anchorEl, set_FA_AnchorEl] = useState(null);
  const open_FA_Popover = Boolean(fa_anchorEl);

  useEffect(() => {}, [project]);

  return (
    <>
      <div style={{ display: "flex" }}>
        {/* Pre-planning */}
        <LinearProgress
          variant="determinate"
          sx={{
            height: 10,
            width: "90%",
            borderRadius: 0,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: lightGray,
            },
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: project?.isInRevision
                ? warningOrange
                : primaryBlue, // progress colour
            },
          }}
          value={
            ["a"].includes(project?.stage[0]) && !project.projectClosed
              ? 0
              : 100
          }
          onMouseEnter={(event) => set_PP_AnchorEl(event.currentTarget)}
          onMouseLeave={() => set_PP_AnchorEl(null)}
        />
        <MuiPopover
          id="pre-planning-popover"
          disableRestoreFocus
          sx={{
            pointerEvents: "none",
          }}
          open={open_PP_Popover}
          anchorEl={pp_anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => set_PP_AnchorEl(null)}
        >
          <div style={{ padding: 10, fontSize: "0.8em", width: 250 }}>
            <b>{t("Pre-planning")}</b>
            <br />
            <p>
              {t(
                "Project Developer submits new project request and municipality has initial considerations about the project and determines a timeline for the planning work."
              )}
            </p>
          </div>
        </MuiPopover>
        <br />

        {/* Initial public consultation */}
        <LinearProgress
          variant="determinate"
          sx={{
            marginLeft: 1,
            height: 10,
            width: "100%",
            borderRadius: 0,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: lightGray,
            },
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: project?.isInRevision
                ? warningOrange
                : primaryBlue, // progress colour
            },
          }}
          value={
            ["a", "b"].includes(project?.stage[0]) && !project.projectClosed
              ? project?.stage[0] === "b"
                ? 50
                : 0
              : 100
          }
          onMouseEnter={(event) => set_IPC_AnchorEl(event.currentTarget)}
          onMouseLeave={() => set_IPC_AnchorEl(null)}
        />
        <MuiPopover
          id="pre-planning-popover"
          disableRestoreFocus
          sx={{
            pointerEvents: "none",
          }}
          open={open_IPC_Popover}
          anchorEl={ipc_anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => set_IPC_AnchorEl(null)}
        >
          <div style={{ padding: 10, fontSize: "0.8em", width: 250 }}>
            <b>{t("Initial Public Consultation")}</b>
            <br />
            <p>
              {t(
                "A period of typically two weeks in which citizens are engaged in the project by the municipality. Citizens are informed of the project and the upcoming planning process."
              )}
            </p>
          </div>
        </MuiPopover>

        {/* Planning process */}
        <LinearProgress
          variant="determinate"
          sx={{
            marginLeft: 1,
            height: 10,
            width: "90%",
            borderRadius: 0,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: lightGray,
            },
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: project?.isInRevision
                ? warningOrange
                : primaryBlue, // progress colour
            },
          }}
          value={
            ["a", "b", "c"].includes(project?.stage[0]) &&
            !project.projectClosed
              ? project?.stage[0] === "c"
                ? 50
                : 0
              : 100
          }
          onMouseEnter={(event) => set_PROCESS_AnchorEl(event.currentTarget)}
          onMouseLeave={() => set_PROCESS_AnchorEl(null)}
        />
        <MuiPopover
          id="pre-planning-popover"
          disableRestoreFocus
          sx={{
            pointerEvents: "none",
          }}
          open={open_PROCESS_Popover}
          anchorEl={process_anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => set_PROCESS_AnchorEl(null)}
        >
          <div style={{ padding: 10, fontSize: "0.8em", width: 250 }}>
            <b>{t("Planning Process")}</b>
            <br />
            <p>
              {t(
                "Environmental Report and Environmental Impact Assessment Report are prepared by the Project Developer and the Permitting Agent."
              )}
            </p>
          </div>
        </MuiPopover>

        {/* Final Public consultation */}
        <LinearProgress
          variant="determinate"
          sx={{
            marginLeft: 1,
            height: 10,
            width: "90%",
            borderRadius: 0,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: lightGray,
            },
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: project?.isInRevision
                ? warningOrange
                : primaryBlue, // progress colour
            },
          }}
          value={
            ["a", "b", "c", "d"].includes(project?.stage[0]) &&
            !project.projectClosed
              ? project?.stage[0] === "d"
                ? 50
                : 0
              : 100
          }
          onMouseEnter={(event) => set_PC_AnchorEl(event.currentTarget)}
          onMouseLeave={() => set_PC_AnchorEl(null)}
        />
        <MuiPopover
          id="pre-planning-popover"
          disableRestoreFocus
          sx={{
            pointerEvents: "none",
          }}
          open={open_PC_Popover}
          anchorEl={pc_anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => set_PC_AnchorEl(null)}
        >
          <div style={{ padding: 10, fontSize: "0.8em", width: 250 }}>
            <b>{t("Final Public Consultation")}</b>
            <br />
            <p>
              {t(
                "Municipality publishes proposal for municipal planning and associated EIA report. A period of at least eight weeks starts in which citizens and other stakeholders are engaged in the project by the municipality."
              )}
            </p>
          </div>
        </MuiPopover>

        {/* Final Approval */}
        <LinearProgress
          variant="determinate"
          sx={{
            marginLeft: 1,
            height: 10,
            width: "90%",
            borderRadius: 0,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: lightGray,
            },
            [`& .${linearProgressClasses.bar}`]: {
              backgroundColor: project?.isInRevision
                ? warningOrange
                : primaryBlue, // progress colour
            },
          }}
          value={
            ["a", "b", "c", "d", "e"].includes(project?.stage[0]) &&
            !project.projectClosed
              ? project?.stage[0] === "e"
                ? 50
                : 0
              : 100
          }
          onMouseEnter={(event) => set_FA_AnchorEl(event.currentTarget)}
          onMouseLeave={() => set_FA_AnchorEl(null)}
        />
        <MuiPopover
          id="pre-planning-popover"
          disableRestoreFocus
          sx={{
            pointerEvents: "none",
          }}
          open={open_FA_Popover}
          anchorEl={fa_anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          onClose={() => set_FA_AnchorEl(null)}
        >
          <div style={{ padding: 10, fontSize: "0.8em", width: 250 }}>
            <b>{t("Final Approval")}</b>
            <br />
            <p>
              {t(
                "The municipality decides on the adoption of the plan proposal."
              )}
            </p>
          </div>
        </MuiPopover>
      </div>
    </>
  );
};
