import { useState, useContext, useEffect, createContext } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { UserContext } from "../../UserContext";
import DataPrivacyPopup from "../../DataPrivacyPopup";
import { useParams, useNavigate } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import "../../i18n";
import {
  denmarkMunicipalityList,
  warningOrange,
  primaryBlue,
  primaryGreen,
} from "../../shared";
import * as queries from "../../graphql/queries";
import * as optimizedQueries from "../../graphql/optimizedQueries";
import * as mutations from "../../graphql/mutations";
import {
  AppLayout,
  ContentLayout,
  Header,
  Modal,
  SpaceBetween,
  BreadcrumbGroup,
  Grid,
  Box,
  Button,
  FormField,
  StatusIndicator,
  Select,
  Link,
  Input,
} from "@cloudscape-design/components";
import { Tooltip, IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import NavigationBar from "../NavigationBar";
import Overview from "./Overview";
import Documents from "./Documents";
import Tasks from "./Tasks";
import Activities from "./Activities";
import Summary from "./Summary";

export const ProjectDetailsContext = createContext(null);

export default function ProjectDashboard() {
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext);
  const navigate = useNavigate();
  const { id } = useParams(); // projectId in URL parameter
  var [projectDetails, setProjectDetails] = useState({});
  var [allComments, setAllComments] = useState([]);
  const [viewingStage, setViewingStage] = useState();
  const [closeProjectConfirmModal, showCloseProjectConfirmModal] =
    useState(false);
  const [loadingCircle, showLoadingCircle] = useState(false);
  const [agentAssignmentPending, setAgentAssignmentPending] = useState(false);
  const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [assignedAgent, setAssignedAgent] = useState({
    value: "",
    label: "",
  });

  const ProjectDetailsHeaders = [
    { label: t("Property"), key: "property" },
    { label: t("Value"), key: "value" },
    { label: t("Unit"), key: "unit" },
  ];

  const ProjectDetailsData = [
    {
      property: t("Additional information"),
      value: projectDetails?.additionalInfo,
      unit: "",
    },
    {
      property: t("Overall Capacity"),
      value: projectDetails?.capacity,
      unit: "MW",
    },
    {
      property: t("Description"),
      value: projectDetails?.description,
      unit: "",
    },
    {
      property: t("Expected rotor diameter"),
      value: projectDetails?.diameter,
      unit: "m",
    },
    {
      property: t("Expected height"),
      value: projectDetails?.height,
      unit: "m",
    },
    {
      property: t("Location - Latitude"),
      value: projectDetails?.latitude,
      unit: "MW",
    },
    {
      property: t("Location - Longitude"),
      value: projectDetails?.longitude,
      unit: "MW",
    },
    {
      property: t("Municipality"),
      value: projectDetails?.municipalities
        ?.map(
          (m) => denmarkMunicipalityList.find((dm) => dm.value === m)?.label
        )
        .join(", "),
      unit: "",
    },
    {
      property: t("Is there a pre-agreement with owners?"),
      value: projectDetails?.preAgreementLandowners ? t("Yes") : t("No"),
      unit: "",
    },
    { property: t("Type"), value: t(projectDetails?.projectType), unit: "" },
    {
      property: t(
        "Information about community engagement and the current relationship to neighbours"
      ),
      value: projectDetails?.relationNeighbours,
      unit: "",
    },
    {
      property: t("Expected rotor diameter"),
      value: projectDetails?.rotorDiameter,
      unit: "m",
    },
    { property: t("Size"), value: projectDetails?.size, unit: t("hectares") },
    { property: t("Start Date"), value: projectDetails?.startDate, unit: "" },
    { property: t("End Date"), value: projectDetails?.endDate, unit: "" },
  ];

  const [projectWebsite, setProjectWebsite] = useState("");
  const [editWebsite, setEditWebsite] = useState(false);
  const updateProjectWebsite = async () => {
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id,
          contact_link: projectWebsite,
        },
      })
    )
      .then(async () => {
        await getProjectDetails();
      })
      .catch((error) => console.error(error));
  };

  const validateUrl = (url) => {
    // ensure URL starts with HTTP/HTTPS (https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url)
    const urlRegExp =
      /https?:\/\/?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
    if (url) {
      if (!url.match(urlRegExp)) return "Link must start with http(s)://";
    } else return;
  };

  const agentsOptions = projectDetails?.agents?.items
    ? [...projectDetails?.agents?.items.map((r) => r.persona)]
    : [];

  const listPersonas = async () => {
    await API.graphql(graphqlOperation(queries.listPersonas))
      .then((res) => {
        setAssigneeOptions(res.data.listPersonas.items);
      })
      .catch((error) => console.error(error));
  };

  const inheritedSetViewingStage = (stage) => setViewingStage(stage);

  const getProjectDetails = async () => {
    const res = await API.graphql(
      graphqlOperation(optimizedQueries.getProject, {
        id: id,
      })
    );
    if (res.data.getProject) {
      setViewingStage(res.data.getProject.stage);
      setProjectDetails((projectDetails = res.data.getProject));

      console.log(projectDetails);

      let myAllComments = [];
      for (const doc of res.data.getProject.documents.items) {
        myAllComments = [...myAllComments, ...doc?.comments?.items];
      }
      setAllComments(
        myAllComments.map((c) => ({
          comment: c?.comment,
          document: c?.document?.name,
          version: c?.version,
          section: c?.section,
          page: c?.page,
          date: c?.createdAt,
          by: `${c?.persona.family_name}, ${c?.persona.given_name} <${c?.persona.email}>`,
        }))
      );

      // If project has an assigned agent then save the agent details. Otherwise show Not assigned
      if (projectDetails?.mainAgentId) {
        setAssignedAgent({
          value: projectDetails?.mainAgentId,
          label:
            projectDetails?.mainAgent?.given_name +
            " " +
            projectDetails?.mainAgent?.family_name,
        });
      } else {
        setAssignedAgent({
          value: "notAssigned",
          label: "Not Assigned",
        });
      }
    }
  };

  // Redirect to 404 page if project no longer exists (eg. deleted)
  const checkProjectExists = async () => {
    const res = await API.graphql(
      graphqlOperation(optimizedQueries.getProject, {
        id: id,
      })
    );
    if (res.data.getProject === null) navigate("/404");
  };

  const assignProjectToAgent = async ({ detail }) => {
    setAgentAssignmentPending(true);
    await API.graphql(
      graphqlOperation(queries.listTasks, {
        filter: {
          projectId: {
            eq: id,
          },
          stage: {
            eq: "a_preplanning",
          },
          assigneeId: {
            attributeExists: false,
          },
        },
        limit: 5000,
      })
    )
      .then(async (res) => {
        // If project has any unnassign tasks, then also assign those tasks to the agent
        for (const task of res.data.listTasks.items) {
          const taskInput = {
            id: task.id,
            assigneeId: detail.selectedOption.value,
          };
          await API.graphql(
            graphqlOperation(mutations.updateTask, {
              input: taskInput,
            })
          );
        }
      })
      .catch((error) => console.error(error));

    await API.graphql(
      graphqlOperation(queries.listDocuments, {
        filter: {
          projectId: {
            eq: id,
          },
          reviewerId: {
            ne: detail.selectedOption.value,
          },
        },
        limit: 5000,
      })
    )
      .then(async (res) => {
        // If project has any unnassign tasks, then also assign those tasks to the agent
        for (const document of res.data.listDocuments.items) {
          const documentInput = {
            id: document.id,
            reviewerId: detail.selectedOption.value,
          };
          await API.graphql(
            graphqlOperation(mutations.updateDocument, {
              input: documentInput,
            })
          ).then((res) => {});
        }
      })
      .catch((error) => console.error(error));

    // assign project to agent
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id,
          mainAgentId: detail.selectedOption.value,
          admins: [...projectDetails?.admins, detail.selectedOption.value],
        },
      })
    )
      .then((res) => {
        setAgentAssignmentPending(false);
        getProjectDetails();
      })
      .catch((error) => console.error(error));
  };

  const assignProjectToMe = async () => {
    await API.graphql(
      graphqlOperation(queries.listTasks, {
        projectId: id,
        filter: {
          projectId: {
            eq: id,
          },
          stage: {
            eq: "a_preplanning",
          },
          assigneeId: {
            attributeExists: false,
          },
        },
        limit: 5000,
      })
    )
      .then(async (res) => {
        // If project has any unnassigned tasks, then also assign those tasks to the agent
        for (const task of res.data.listTasks.items) {
          const taskInput = {
            id: task.id,
            assigneeId: userDetails.id,
          };
          await API.graphql(
            graphqlOperation(mutations.updateTask, {
              input: taskInput,
            })
          );
        }
      })
      .catch((error) => console.error(error));

    await API.graphql(
      graphqlOperation(queries.listDocuments, {
        projectId: id,
        filter: {
          projectId: {
            eq: id,
          },
        },
        limit: 5000,
      })
    )
      .then(async (res) => {
        // If project has any unnassign documents, then also assign those documents to the agent
        for (const document of res.data.listDocuments.items) {
          const documentInput = {
            id: document.id,
            reviewerId: userDetails.id,
          };
          if (!document?.assigneeId)
            documentInput["assigneeId"] = userDetails.id;
          await API.graphql(
            graphqlOperation(mutations.updateDocument, {
              input: documentInput,
            })
          );
        }
      })
      .catch((error) => console.error(error));

    // assign project to agent
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id,
          mainAgentId: userDetails.id,
          adminGroups: userDetails.domain,
        },
      })
    )
      .then((res) => {
        getProjectDetails();
      })
      .catch((error) => console.error(error));
  };

  const closeProject = async (projectClosed) => {
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: { id, projectClosed },
      })
    )
      .then(async (res) => {
        console.log("Successfully closed project!");
        await getProjectDetails();
      })
      .catch((error) => console.error(error.message));
  };

  const goBackAStage = async () => {
    const stages = [
      "a_preplanning",
      "b_initial_consultation",
      "c_planning",
      "d_final_consultation",
      "e_approval",
      "f_closed",
    ];
    let previousStage = 0;
    for (const stage in stages) {
      if (stages[stage] === projectDetails?.stage) {
        previousStage = parseInt(stage) - 1;
      }
    }
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: { id, stage: stages[previousStage] },
      })
    )
      .then((res) => {
        console.log("Successfully updated project!");
        getProjectDetails();
      })
      .catch((error) => console.error(error.message));
  };

  const goBack = async () => {
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: { id, stage: "a_preplanning" },
      })
    )
      .then((res) => {
        console.log("Successfully updated project worfklow flag!");
        getProjectDetails();
      })
      .catch((error) => console.error(error.message));
  };

  const updateVisitedProjects = async () => {
    if (projectDetails?.id && userDetails.id) {
      const visitedProject = {
        projectId: projectDetails?.id,
        visitedAt: new Date().toISOString(),
      };
      const oldVisitedProjects =
        userDetails.visitedProjects && userDetails.visitedProjects?.length
          ? userDetails.visitedProjects.filter(
              (vp) => vp.projectId !== projectDetails?.id
            )
          : [];
      const input = {
        id: userDetails.id,
        visitedProjects: [...oldVisitedProjects, visitedProject],
      };
      await API.graphql(graphqlOperation(mutations.updatePersona, { input }))
        .then((res) => {
          console.log("Successfully updated visited projects!");
        })
        .catch((error) => console.error(error.message));
    }
  };

  useEffect(() => {
    listPersonas();
    getProjectDetails();
    checkProjectExists();
  }, []);

  useEffect(() => {
    updateVisitedProjects();
    setProjectWebsite(projectDetails?.contact_link);
  }, [projectDetails, userDetails]);

  return (
    <Authenticator>
      {/* Data Privacy disclaimer popup  */}
      {userDetails.dateOfPrivacyConsent === null && <DataPrivacyPopup />}
      <NavigationBar />
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={
          <ContentLayout
            header={
              <SpaceBetween size="xl">
                <div />
                <BreadcrumbGroup
                  ariaLabel="Breadcrumbs"
                  items={[
                    { text: t("Dashboard"), href: "/dashboard" },
                    {
                      text: t("Project Dashboard"),
                      href: "#",
                    },
                  ]}
                />
                <Header
                  variant="h1"
                  description={
                    <SpaceBetween direction="horizontal" size="xs">
                      {t("Created on") +
                        " " +
                        new Date(projectDetails?.createdAt).toDateString() +
                        " " +
                        t("by") +
                        " " +
                        projectDetails?.mainDeveloper?.given_name +
                        " " +
                        projectDetails?.mainDeveloper?.family_name}
                      {projectDetails?.municipalities && (
                        <>
                          •
                          <Link external href="/map">
                            {t("Public page")}
                          </Link>
                        </>
                      )}
                      {projectDetails?.mainDeveloper?.id === userDetails.id ? (
                        <>
                          •
                          {editWebsite ? (
                            <div style={{ marginTop: -10 }}>
                              <SpaceBetween direction="horizontal" size="xxs">
                                <FormField
                                  constraintText={t(
                                    "Must start with http(s)://"
                                  )}
                                  errorText={validateUrl(projectWebsite)}
                                >
                                  <Input
                                    type="url"
                                    value={projectWebsite}
                                    onChange={({ detail }) =>
                                      setProjectWebsite(detail.value)
                                    }
                                  />
                                </FormField>
                                <Tooltip title={t("Save Changes")}>
                                  <IconButton
                                    disabled={!projectWebsite}
                                    onClick={async () => {
                                      await updateProjectWebsite();
                                      setEditWebsite(false);
                                    }}
                                  >
                                    <CheckIcon
                                      sx={{
                                        color: !projectWebsite
                                          ? "gray"
                                          : primaryBlue,
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={t("Cancel")}>
                                  <IconButton
                                    onClick={() => setEditWebsite(false)}
                                  >
                                    <CloseIcon sx={{ color: primaryBlue }} />
                                  </IconButton>
                                </Tooltip>
                              </SpaceBetween>
                            </div>
                          ) : (
                            <>
                              {projectDetails?.contact_link ? (
                                <SpaceBetween direction="horizontal" size="xs">
                                  <Link
                                    external
                                    href={projectDetails?.contact_link}
                                  >
                                    {t("Project website")}
                                  </Link>
                                  <Tooltip title={t("Edit website")}>
                                    <IconButton
                                      onClick={() => setEditWebsite(true)}
                                    >
                                      <EditIcon
                                        sx={{
                                          mt: -1,
                                          fontSize: "0.8em",
                                          color: primaryBlue,
                                        }}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                </SpaceBetween>
                              ) : (
                                <div style={{ marginTop: -5 }}>
                                  <Button onClick={() => setEditWebsite(true)}>
                                    {t("Add project website")}
                                  </Button>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        // For non-developers, only show the project website link with no edit options
                        projectDetails?.contact_link && (
                          <>
                            •
                            <Link external href={projectDetails?.contact_link}>
                              {t("Project website")}
                            </Link>
                          </>
                        )
                      )}
                    </SpaceBetween>
                  }
                  actions={
                    <SpaceBetween direction="horizontal" size="l">
                      {userDetails.role === "agents" && (
                        <Button
                          variant="primary"
                          iconAlign="left"
                          iconName="download"
                        >
                          <CSVLink
                            separator={";"}
                            style={{ textDecoration: "none", color: "black" }}
                            filename={`${projectDetails?.name}-${t(
                              "Project Summary"
                            )}-${new Date().toLocaleDateString()}.csv`}
                            data={ProjectDetailsData}
                            headers={ProjectDetailsHeaders}
                          >
                            {t("Export Summary")}
                          </CSVLink>
                        </Button>
                      )}
                      {!assignedAgent.value || assignedAgent.value === null ? (
                        <Button variant="primary" onClick={assignProjectToMe}>
                          {t("Assign Project to Me")}
                        </Button>
                      ) : (
                        <div style={{ marginTop: -23 }}>
                          <FormField label={t("Assigned to")}>
                            <Select
                              // Developers cannot use this feature. Only Agents can assign project to other users
                              disabled={
                                userDetails.id !==
                                  projectDetails?.mainAgentId ||
                                agentAssignmentPending ||
                                projectDetails?.projectClosed ||
                                projectDetails?.stage[0] === "f"
                              }
                              selectedAriaLabel="Assigned to selected"
                              selectedOption={
                                agentAssignmentPending
                                  ? {
                                      value: "",
                                      label: `${t("Please wait")}...`,
                                    }
                                  : assignedAgent
                              }
                              onChange={assignProjectToAgent}
                              options={agentsOptions?.map((assignee) => ({
                                value: assignee?.id,
                                label: `${assignee?.given_name} ${assignee?.family_name}`,
                              }))}
                            />
                          </FormField>
                        </div>
                      )}
                    </SpaceBetween>
                  }
                >
                  {projectDetails?.name +
                    " " +
                    t("by") +
                    " " +
                    projectDetails?.contact_organization}
                  <i
                    style={{
                      marginLeft: 10,
                      color: projectDetails?.projectClosed
                        ? projectDetails?.stage === "f_closed"
                          ? primaryGreen
                          : warningOrange
                        : projectDetails?.isInRevision && primaryBlue,
                    }}
                  >
                    {projectDetails?.projectClosed
                      ? projectDetails?.stage === "f_closed"
                        ? t("Project Completed")
                        : t("Project Closed")
                      : projectDetails?.isInRevision
                      ? t("In Revision")
                      : ""}
                  </i>
                </Header>
                <div />
              </SpaceBetween>
            }
          >
            <ProjectDetailsContext.Provider
              value={{ projectDetails, assigneeOptions }}
            >
              <SpaceBetween size="l">
                <Overview
                  getProjectDetails={getProjectDetails}
                  setViewingStage={inheritedSetViewingStage}
                  viewingStage={viewingStage}
                />
                <Grid gridDefinition={[{ colspan: 5 }, { colspan: 7 }]}>
                  <Documents viewingStage={viewingStage} />
                  <Tasks viewingStage={viewingStage} />
                </Grid>
                <Activities
                  projectDetails={projectDetails}
                  allComments={allComments}
                  userDetails={userDetails}
                />
                <Summary getProjectDetails={getProjectDetails} />
              </SpaceBetween>
            </ProjectDetailsContext.Provider>

            <Box padding="m">
              {/* Only Admins and mainAgents can close projects */}
              {(projectDetails?.admins &&
                projectDetails?.admins[0] === userDetails.id) ||
                (projectDetails?.mainAgentId &&
                  projectDetails?.mainAgentId === userDetails.id && (
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="s">
                        <Button
                          disabled={
                            projectDetails?.stage === "a_preplanning" ||
                            projectDetails?.projectClosed
                          }
                          onClick={() => goBack()}
                        >
                          {t("Start Over")}
                        </Button>
                        <Button
                          disabled={
                            projectDetails?.stage === "a_preplanning" ||
                            projectDetails?.projectClosed
                          }
                          onClick={() => goBackAStage()}
                        >
                          {t("Go Back a Stage")}
                        </Button>
                      </SpaceBetween>
                      {projectDetails?.projectClosed && (
                        <Button
                          onClick={async () => {
                            if (projectDetails?.stage === "f_closed") {
                              await goBackAStage();
                            }
                            await closeProject(false);
                          }}
                        >
                          {t("Re-open project")}
                        </Button>
                      )}
                    </Box>
                  ))}
            </Box>

            {/* -------- Close project popup modal -------- */}
            <Modal
              onDismiss={() => showCloseProjectConfirmModal(false)}
              visible={closeProjectConfirmModal}
              footer={
                <>
                  <Box float="left">
                    {loadingCircle && (
                      <StatusIndicator type="loading">
                        {t("Saving changes")}...
                      </StatusIndicator>
                    )}
                  </Box>
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        variant="link"
                        onClick={() => showCloseProjectConfirmModal(false)}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        disabled={loadingCircle}
                        onClick={async () => {
                          showLoadingCircle(true);
                          await closeProject(true);
                          showCloseProjectConfirmModal(false);
                          showLoadingCircle(false);
                        }}
                      >
                        {t("Close Project")}
                      </Button>
                    </SpaceBetween>
                  </Box>
                </>
              }
              header={t("Are you sure you want to close project?")}
            ></Modal>
          </ContentLayout>
        }
      />
    </Authenticator>
  );
}
