import { useEffect, useState, useContext, createContext } from "react";
import { UserContext } from "../../UserContext";
import { API, graphqlOperation, Storage } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import * as queries from "../../graphql/queries";
import * as optimizedQueries from "../../graphql/optimizedQueries";
import * as mutations from "../../graphql/mutations";
import { CSVLink } from "react-csv";
import {
  blueLink,
  currentDate,
  Divider,
  warningOrange,
  lightGray,
  primaryBlue,
  primaryGreen,
  getDeadlineDate,
  getDeadlineAsNumber,
  getProgressColor,
  isDeadlinePassed,
  calculatePriority,
  denmarkMunicipalityList,
  MapCodeToLabel,
} from "../../shared";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Button,
  Alert,
  Tabs,
  Box,
  Link,
  Icon,
  Popover as CloudscapePopover,
  Table,
  Grid,
  Pagination,
  SegmentedControl,
  Modal,
} from "@cloudscape-design/components";
import { useCollection } from "@cloudscape-design/collection-hooks";
import NavigationBar from "../NavigationBar";
import Productivity from "./Productivity";
import DeveloperFirstTime from "./DeveloperFirstTime";
import DataPrivacyPopup from "../../DataPrivacyPopup";
import {
  Popover,
  Typography,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DescriptionIcon from "@mui/icons-material/Description";
import TopicIcon from "@mui/icons-material/Topic";

export const UserProjectsContext = createContext(null);

export default function Dashboard() {
  const { t } = useTranslation();
  const date = new Date();
  const { userDetails } = useContext(UserContext);
  const [isPageLoading, setPageLoading] = useState(true);
  let [userProjects, setUserProjects] = useState([]);
  const [closedProjects, setClosedProjects] = useState([]);
  const [userTasks, setUserTasks] = useState([]);
  const [nextWeeksTasks, setNextWeeksTasks] = useState([]);
  const [thisWeeksTasks, setThisWeeksTasks] = useState([]);
  const [unassignedProjects, setUnassignedProjects] = useState([]);
  const [domainProjects, setDomainProjects] = useState([]);
  const [selectedProjectTab, setSelectedProjectTab] = useState("myProjects");
  const [selectedTaskId, setSelectedTaskId] = useState("thisWeek");
  const [agentAssignmentPending, setAgentAssignmentPending] = useState(false);

  // Closed projects download all files
  const [downloadModal, showDownloadModal] = useState({});
  const [projectDocumentComments, setProjectDocumentComments] = useState([]);

  const ProjectDetailsHeaders = [
    { label: t("Property"), key: "property" },
    { label: t("Value"), key: "value" },
    { label: t("Unit"), key: "unit" },
  ];
  const CommentsHeaders = [
    { label: t("Comment"), key: "comment" },
    { label: t("Date"), key: "date" },
    { label: t("Author"), key: "by" },
  ];
  const DocumentCommentsHeaders = [
    { label: t("Comment"), key: "comment" },
    { label: t("Date"), key: "date" },
    { label: t("Author"), key: "by" },
    { label: t("Version"), key: "version" },
    { label: t("Page"), key: "page" },
    { label: t("Section"), key: "section" },
  ];

  const getProjectDetails = (project) => {
    return [
      {
        property: t("Additional information"),
        value: project?.additionalInfo,
        unit: "",
      },
      {
        property: t("Overall Capacity"),
        value: project?.capacity,
        unit: "MW",
      },
      {
        property: t("Description"),
        value: project?.description,
        unit: "",
      },
      {
        property: t("Expected rotor diameter"),
        value: project?.diameter,
        unit: "m",
      },
      {
        property: t("Expected height"),
        value: project?.height,
        unit: "m",
      },
      {
        property: t("Location - Latitude"),
        value: project?.latitude,
        unit: "MW",
      },
      {
        property: t("Location - Longitude"),
        value: project?.longitude,
        unit: "MW",
      },
      {
        property: t("Municipality"),
        value: project?.municipalities
          ?.map(
            (m) => denmarkMunicipalityList.find((dm) => dm.value === m)?.label
          )
          .join(", "),
        unit: "",
      },
      {
        property: t("Is there a pre-agreement with owners?"),
        value: project?.preAgreementLandowners ? t("Yes") : t("No"),
        unit: "",
      },
      { property: t("Type"), value: t(project?.projectType), unit: "" },
      {
        property: t(
          "Information about community engagement and the current relationship to neighbours"
        ),
        value: project?.relationNeighbours,
        unit: "",
      },
      {
        property: t("Expected rotor diameter"),
        value: project?.rotorDiameter,
        unit: "m",
      },
      { property: t("Size"), value: project?.size, unit: t("hectares") },
      { property: t("Start Date"), value: project?.startDate, unit: "" },
      { property: t("End Date"), value: project?.endDate, unit: "" },
    ];
  };

  const [documentHasFiles, setDocumentHasFiles] = useState(true);
  const getDocumentName = (d) => d.key.split("/")[d.key.split("/").length - 1];
  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };

  const downloadAllFilesFromS3 = async (projectId, projectName) => {
    await Storage.list(`projects/${projectId}/`, {
      level: "public",
    })
      .then(async ({ results }) => {
        let sortedFiles = results.sort((a, b) => {
          const nameA = a.key;
          const nameB = b.key;
          if (nameA < nameB) return 1;
          if (nameA > nameB) return -1;
          return 0;
        });
        if (sortedFiles.length !== 0) {
          setDocumentHasFiles(true);
          for (const file of sortedFiles) {
            const signedURL = await Storage.get(file.key, { download: true });
            downloadBlob(
              signedURL.Body,
              `${projectName} - (${file.lastModified.toLocaleString()}) ${getDocumentName(
                file
              )}`
            );
          }
        } else setDocumentHasFiles(false);
      })
      .catch((err) => console.error(err));
  };

  // Overall Tasks Table pagination
  const { items, paginationProps, collectionProps } = useCollection(userTasks, {
    pagination: { pageSize: 15 },
    sorting: {},
  });

  const createLabelFunction = (columnName) => {
    return ({ sorted, descending }) => {
      const sortState = sorted
        ? `sorted ${descending ? "descending" : "ascending"}`
        : "not sorted";
      return `${columnName}, ${sortState}.`;
    };
  };

  const getUserTasks = async () => {
    if (userDetails.id) {
      const variables = {
        filter: {
          assigneeId: {
            eq: userDetails.id,
          },
          done: {
            ne: true,
          },
        },
        limit: 5000,
      };
      let res = await API.graphql(
        graphqlOperation(queries.listTasks, variables)
      );
      let tasks = res.data.listTasks.items.sort((a, b) => {
        if (a.deadline && b.deadline)
          return new Date(a.deadline) - new Date(b.deadline);
        else return a.deadline ? -1 : 1;
      });
      setUserTasks(tasks);
    }
  };

  const getThisWeeksTasks = async () => {
    const today = new Date();
    const weekDay = today.getDay() === 0 ? 7 : date.getDay();
    const nextWeekStartsAt = new Date();
    nextWeekStartsAt.setDate(date.getUTCDate() + (8 - weekDay));
    nextWeekStartsAt.setHours(0);
    nextWeekStartsAt.setMinutes(0);
    nextWeekStartsAt.setSeconds(0);
    const thisWeekStartedAt = new Date(nextWeekStartsAt);
    thisWeekStartedAt.setDate(thisWeekStartedAt.getDate() - 7);

    if (userDetails.id) {
      const variables = {
        filter: {
          assigneeId: {
            eq: userDetails.id,
          },
          deadline: {
            lt: nextWeekStartsAt,
          },
          done: {
            ne: true,
          },
        },
        limit: 5000,
      };
      let res = await API.graphql(
        graphqlOperation(queries.listTasks, variables)
      );
      let tasks = res.data.listTasks.items.sort((a, b) => {
        if (a.deadline && b.deadline)
          return new Date(a.deadline) - new Date(b.deadline);
        else return a.deadline ? -1 : 1;
      });
      setThisWeeksTasks(tasks);
    }
  };

  const getNextWeeksTasks = async () => {
    const today = new Date();
    const weekDay = today.getDay() === 0 ? 7 : date.getDay();
    const nextWeekStartsAt = new Date();
    nextWeekStartsAt.setDate(date.getUTCDate() + (8 - weekDay));
    nextWeekStartsAt.setHours(0);
    nextWeekStartsAt.setMinutes(0);
    nextWeekStartsAt.setSeconds(0);
    const theWeekAfterStartsAt = new Date(nextWeekStartsAt);
    theWeekAfterStartsAt.setDate(theWeekAfterStartsAt.getDate() + 7);

    if (userDetails.id) {
      const variables = {
        filter: {
          assigneeId: {
            eq: userDetails.id,
          },
          deadline: {
            between: [nextWeekStartsAt, theWeekAfterStartsAt],
          },
          done: {
            ne: true,
          },
        },
        limit: 5000,
      };
      let res = await API.graphql(
        graphqlOperation(queries.listTasks, variables)
      );
      let tasks = res.data.listTasks.items.sort((a, b) => {
        if (a.deadline && b.deadline)
          return new Date(a.deadline) - new Date(b.deadline);
        else return a.deadline ? -1 : 1;
      });
      setNextWeeksTasks(tasks);
    }
  };

  const diffDays = (deadline) => {
    if (deadline) {
      const deadlineUTC = new Date(deadline);
      const currentDateUTC = new Date(currentDate);
      const diffTime = Math.abs(deadlineUTC - currentDateUTC);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }
  };

  // get projects assigned to the user
  const getUserProjects = async () => {
    if (userDetails.role) {
      let variables = {};
      switch (userDetails.role) {
        case "developers":
          variables = {
            filter: {
              mainDeveloperId: {
                eq: userDetails.id,
              },
              projectClosed: {
                ne: true,
              },
            },
          };
          break;
        default:
          variables = {
            filter: {
              mainAgentId: {
                eq: userDetails.id,
              },
              projectClosed: {
                ne: true,
              },
            },
          };
      }
      await API.graphql(
        graphqlOperation(optimizedQueries.listProjects, variables)
      )
        .then(async (res) => {
          userProjects = res.data.listProjects.items;
          setUserProjects([...userProjects]);
          let additionalProjects = [];
          await API.graphql(
            graphqlOperation(queries.listProjectAgents, {
              filter: {
                personaId: { eq: userDetails?.id },
              },
            })
          ).then(async (re) => {
            additionalProjects = [
              ...re?.data?.listProjectAgents?.items
                ?.filter(
                  (p) =>
                    p?.project.mainAgent !== userDetails?.id &&
                    p?.project.mainDeveloper?.id !== userDetails?.id &&
                    !p?.project.projectClosed
                )
                .map((p) => p.projectId),
            ];
            await API.graphql(
              graphqlOperation(queries.listProjectDevelopers, {
                filter: {
                  personaId: { eq: userDetails?.id },
                },
              })
            ).then(async (re) => {
              additionalProjects = [
                ...additionalProjects,
                ...re?.data?.listProjectDevelopers?.items
                  ?.filter(
                    (p) =>
                      p?.project.mainAgent !== userDetails?.id &&
                      p?.project.mainDeveloper?.id !== userDetails?.id &&
                      !p?.project.projectClosed
                  )
                  .map((p) => p.projectId),
              ];
              await API.graphql(
                graphqlOperation(queries.listProjectSmes, {
                  filter: {
                    personaId: { eq: userDetails?.id },
                  },
                })
              ).then(async (re) => {
                additionalProjects = [
                  ...additionalProjects,
                  ...re?.data?.listProjectSmes?.items
                    ?.filter(
                      (p) =>
                        p?.project.mainAgent !== userDetails?.id &&
                        p?.project.mainDeveloper?.id !== userDetails?.id &&
                        !p?.project.projectClosed
                    )
                    .map((p) => p.projectId),
                ];
                additionalProjects = [...new Set(additionalProjects)];
                for (const additionalProject of additionalProjects) {
                  if (
                    !userProjects.map((p) => p.id).includes(additionalProject)
                  ) {
                    await API.graphql(
                      graphqlOperation(optimizedQueries.getProject, {
                        id: additionalProject,
                      })
                    ).then(async (r) => {
                      userProjects = [...userProjects, r.data.getProject];
                      if (!userProjects?.length)
                        setSelectedProjectTab("closed");
                      setUserProjects([...userProjects]);
                    });
                  }
                }
              });
            });
          });
          setPageLoading(false);
        })
        .catch((error) => console.error(error));
    }
  };

  const getUnassignedProjects = async () => {
    if (userDetails.role === "agents") {
      const variables = {
        filter: {
          mainAgentId: {
            attributeExists: false,
          },
          projectClosed: {
            ne: true,
          },
          adminGroups: {
            contains: userDetails.municipality,
          },
        },
      };
      let res = await API.graphql(
        graphqlOperation(queries.listProjects, variables)
      );
      setUnassignedProjects(res.data.listProjects.items);
    }
  };

  const getDomainProjects = async () => {
    if (userDetails.role === "agents") {
      const variables = {
        filter: {
          mainAgentId: {
            ne: userDetails.id,
            attributeExists: true,
          },
          adminGroups: {
            contains: userDetails.municipality,
          },
          projectClosed: {
            ne: true,
          },
        },
      };
      let res = await API.graphql(
        graphqlOperation(queries.listProjects, variables)
      );
      setDomainProjects(res.data.listProjects.items);
    }
  };

  const getClosedProjects = async () => {
    const variables = {
      filter: {
        projectClosed: {
          eq: true,
        },
      },
    };
    await API.graphql(
      graphqlOperation(optimizedQueries.listProjects, variables)
    )
      .then((res) => {
        let myClosedProjects = [];
        if (userDetails?.role === "developers") {
          myClosedProjects = res.data.listProjects.items.filter((p) => {
            return (
              p?.mainDeveloperId === userDetails?.id ||
              p?.developers?.items.includes(
                (pd) => pd.personaId === userDetails?.id
              )
            );
          });
        } else {
          myClosedProjects = res.data.listProjects.items.filter((p) =>
            p.municipalities.includes(userDetails?.municipality)
          );
        }
        setClosedProjects(myClosedProjects);
        let myProjectComments = {};
        for (const project of myClosedProjects) {
          let myAllComments = [];
          for (const doc of project?.documents?.items) {
            myAllComments = [...myAllComments, ...doc?.comments?.items];
          }
          myProjectComments[project.id] = myAllComments.map((c) => ({
            comment: c.comment,
            document: c?.document?.name,
            version: c?.version,
            section: c?.section,
            page: c?.page,
            date: c?.createdAt,
            by: `${c.persona.family_name}, ${c.persona.given_name} <${c.persona.email}>`,
          }));
        }
        setProjectDocumentComments(myProjectComments);
      })
      .catch((error) => console.log(error));
  };

  const assignProjectToMe = async (id) => {
    setAgentAssignmentPending(true);
    await API.graphql(
      graphqlOperation(queries.listTasks, {
        projectId: id,
        filter: {
          projectId: {
            eq: id,
          },
          stage: {
            eq: "a_preplanning",
          },
          assigneeId: {
            attributeExists: false,
          },
        },
        limit: 5000,
      })
    )
      .then(async (res) => {
        // If project has any unnassigned tasks, then also assign those tasks to the agent
        for (const task of res.data.listTasks.items) {
          const taskInput = {
            id: task.id,
            assigneeId: userDetails.id,
          };
          await API.graphql(
            graphqlOperation(mutations.updateTask, {
              input: taskInput,
            })
          );
        }
      })
      .catch((error) => console.error(error));

    await API.graphql(
      graphqlOperation(queries.listDocuments, {
        projectId: id,
        filter: {
          projectId: {
            eq: id,
          },
        },
        limit: 5000,
      })
    )
      .then(async (res) => {
        // If project has any unnassigned documents, then also assign those tasks to the agent
        for (const document of res.data.listDocuments.items) {
          const documentInput = {
            id: document.id,
            reviewerId: userDetails.id,
          };
          if (!document?.assigneeId)
            documentInput["assigneeId"] = userDetails.id;
          await API.graphql(
            graphqlOperation(mutations.updateDocument, {
              input: documentInput,
            })
          );
        }
      })
      .catch((error) => console.error(error));

    // assign project to agent
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id,
          mainAgentId: userDetails.id,
        },
      })
    )
      .then((res) => {
        setSelectedProjectTab("myProjects");
        setAgentAssignmentPending(false);
        getAllProjects();
      })
      .catch((error) => console.error(error));
  };

  const getAllProjects = async () => {
    await getUserProjects();
    await getUnassignedProjects();
    await getDomainProjects();
    await getClosedProjects();
  };

  const getAllTasks = () => {
    getUserTasks();
    getThisWeeksTasks();
    getNextWeeksTasks();
  };

  useEffect(() => {
    document.title = "EasyPermits® Dashboard";
    getAllProjects();
    getAllTasks();
  }, [userDetails?.id]);

  const nextDocument = (document) => {
    return (
      <span>
        {t(document?.name)}{" "}
        <i>
          ({document?.assignedTo?.given_name}{" "}
          {document?.assignedTo?.family_name})
        </i>
      </span>
    );
  };

  const overdueTask = (task) => {
    return (
      <span>
        {t(task?.title)}{" "}
        <i>
          ({task?.assignedTo?.given_name} {task?.assignedTo?.family_name})
        </i>
      </span>
    );
  };

  return (
    <Authenticator>
      {/* Data Privacy disclaimer popup */}
      {userDetails?.dateOfPrivacyConsent === null && <DataPrivacyPopup />}
      {
        // Show Loading text when page mounts instead of DeveloperFirstTime screen when user access Dashboard
        isPageLoading ? (
          <p style={{ textAlign: "center" }}>{t("Loading")}...</p>
        ) : // If current user is a Developer and has not yet created any projects, then show first time welcome screen
        userDetails.role &&
          userDetails.role === "developers" &&
          !userProjects?.length &&
          !closedProjects?.length ? (
          <DeveloperFirstTime />
        ) : (
          // Otherwise show usual Dashboard
          <>
            <NavigationBar />
            <AppLayout
              navigationHide={true}
              toolsHide={true}
              content={
                <ContentLayout
                  header={
                    <SpaceBetween size="xl">
                      <div />
                      <p style={{ color: blueLink, fontSize: "14px" }}>
                        {t("Dashboard")}
                      </p>
                      {userDetails.role === "developers" ? (
                        // Show Submit new project action button for Developers only
                        <Header
                          variant="h1"
                          description={`${t(
                            date.toDateString().slice(0, 3)
                          )} ${t(date.toDateString().slice(4, 7))} ${t(
                            date.toDateString().slice(7)
                          )}`}
                          actions={
                            <Button
                              variant="primary"
                              href="/project-setup"
                              iconAlign="left"
                              iconName="add-plus"
                            >
                              {t("Submit New Project")}
                            </Button>
                          }
                        >
                          {t("Welcome Back")}, {userDetails.given_name}!
                        </Header>
                      ) : (
                        // Don't show action button for Agents
                        <Header
                          variant="h1"
                          description={`${t(
                            date.toDateString().slice(0, 3)
                          )} ${t(date.toDateString().slice(4, 7))} ${t(
                            date.toDateString().slice(7)
                          )} `}
                        >
                          {t("Welcome Back")}, {userDetails.given_name}!
                        </Header>
                      )}
                      <div />
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween size="l">
                    <UserProjectsContext.Provider
                      value={{ userProjects, userDetails }}
                    >
                      <Productivity />
                    </UserProjectsContext.Provider>
                    <Tabs
                      variant="container"
                      tabs={[
                        {
                          label: t("Project Overview"),
                          id: "projectOverview",
                          content: (
                            <>
                              <h2>{t("Project Overview")}</h2>
                              {agentAssignmentPending && (
                                <Box float="right">
                                  <CircularProgress size={20} />
                                  <span style={{ paddingLeft: 10 }}>
                                    <b>{t("Saving changes")}...</b>
                                  </span>
                                </Box>
                              )}
                              <div style={{ height: 15 }} />
                              <SegmentedControl
                                ariaLabelledby="Project Overview Segmented Control"
                                selectedId={selectedProjectTab}
                                onChange={({ detail }) =>
                                  setSelectedProjectTab(detail.selectedId)
                                }
                                label="Project Overview segmented control"
                                options={
                                  userDetails.role === "developers"
                                    ? [
                                        // Developers view
                                        {
                                          text: t("My Projects"),
                                          id: "myProjects",
                                        },

                                        {
                                          text: t("Closed Projects"),
                                          id: "closed",
                                        },
                                      ]
                                    : [
                                        // Agents view
                                        {
                                          text: t("My Projects"),
                                          id: "myProjects",
                                        },
                                        {
                                          text: t(
                                            "All Projects in Municipality"
                                          ),
                                          id: "inMunicipality",
                                        },
                                        {
                                          text: t("Unassigned Projects"),
                                          id: "unassigned",
                                        },
                                        {
                                          text: t("Closed Projects"),
                                          id: "closed",
                                        },
                                      ]
                                }
                              />
                              <div style={{ paddingTop: 20 }}>
                                {selectedProjectTab === "myProjects" ? (
                                  !userProjects ? (
                                    <p>{t("No projects found")}.</p>
                                  ) : (
                                    userProjects.map((project) => (
                                      <div
                                        key={project.id}
                                        style={{ marginBottom: 20 }}
                                      >
                                        <Grid
                                          gridDefinition={[
                                            { colspan: 9 },
                                            { colspan: 3 },
                                          ]}
                                        >
                                          <Box>
                                            {/* Project name, delayed status and Notifications bell */}
                                            <SpaceBetween
                                              direction="horizontal"
                                              size="m"
                                            >
                                              <Link
                                                href={"/project/" + project.id}
                                              >
                                                <h3>
                                                  {project?.name +
                                                    " " +
                                                    t("by") +
                                                    " " +
                                                    project?.contact_organization}{" "}
                                                  (
                                                  {
                                                    project?.mainDeveloper
                                                      .given_name
                                                  }{" "}
                                                  {
                                                    project?.mainDeveloper
                                                      .family_name
                                                  }
                                                  )
                                                </h3>
                                              </Link>

                                              {/* Show Delayed status if there are delayed documents or tasks */}
                                              {project?.tasks?.items.filter(
                                                (task) =>
                                                  task.stage ===
                                                    project.stage &&
                                                  task.deadline &&
                                                  !task.done &&
                                                  isDeadlinePassed(
                                                    task.deadline
                                                  )
                                              ).length ||
                                              project?.documents?.items.filter(
                                                (document) =>
                                                  document.stage ===
                                                    project.stage &&
                                                  document.deadline &&
                                                  document.status !==
                                                    "d_done" &&
                                                  isDeadlinePassed(
                                                    document.deadline
                                                  )
                                              ).length ? (
                                                <div
                                                  style={{
                                                    color: "white",
                                                    width: 65,
                                                    height: 23,
                                                    fontSize: "0.75em",
                                                    borderRadius: 5,
                                                    background: warningOrange,
                                                    textAlign: "center",
                                                    marginTop: 15,
                                                  }}
                                                >
                                                  {t("Delayed")}
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {/* Notifications icon */}
                                              <div style={{ marginTop: 15 }}>
                                                {new Date(
                                                  userDetails?.visitedProjects?.find(
                                                    (vp) =>
                                                      vp.projectId ===
                                                      project.id
                                                  )?.visitedAt
                                                ) <
                                                new Date(
                                                  Math.max(
                                                    new Date(project.updatedAt),
                                                    new Date(
                                                      project.nested_update_at
                                                    )
                                                  )
                                                ) ? (
                                                  <CloudscapePopover
                                                    position="top"
                                                    dismissButton
                                                    size="large"
                                                    triggerType="custom"
                                                    header={t("Notifications")}
                                                    content={
                                                      <>
                                                        {project?.notificationMessage
                                                          ? t(
                                                              project?.notificationMessage
                                                            )
                                                          : t(
                                                              "No new notifications"
                                                            )}
                                                        .
                                                        <SpaceBetween size="s">
                                                          <Tabs
                                                            tabs={[
                                                              // Overdue documents
                                                              {
                                                                label: (
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "0.8em",
                                                                    }}
                                                                  >
                                                                    {t(
                                                                      "Next Document to Submit"
                                                                    )}
                                                                  </span>
                                                                ),
                                                                id: "documents",
                                                                content: project
                                                                  .documents
                                                                  ?.items
                                                                  .length ? (
                                                                  <>
                                                                    {nextDocument(
                                                                      project.documents?.items
                                                                        ?.filter(
                                                                          (d) =>
                                                                            d.stage ===
                                                                            project.stage
                                                                        )
                                                                        ?.sort(
                                                                          (
                                                                            a,
                                                                            b
                                                                          ) =>
                                                                            new Date(
                                                                              a.createdAt
                                                                            ) -
                                                                            new Date(
                                                                              b.createdAt
                                                                            )
                                                                        )
                                                                        .sort(
                                                                          (
                                                                            a,
                                                                            b
                                                                          ) => {
                                                                            if (
                                                                              a.deadline &&
                                                                              b.deadline
                                                                            ) {
                                                                              return (
                                                                                a.deadline -
                                                                                b.deadline
                                                                              );
                                                                            } else {
                                                                              return a.deadline
                                                                                ? -1
                                                                                : 1;
                                                                            }
                                                                          }
                                                                        )[0]
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  <i
                                                                    style={{
                                                                      color:
                                                                        "gray",
                                                                    }}
                                                                  >
                                                                    {t(
                                                                      "No documents available"
                                                                    )}
                                                                    .
                                                                  </i>
                                                                ),
                                                              },
                                                              // Overdue tasks
                                                              {
                                                                label: (
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "0.8em",
                                                                    }}
                                                                  >
                                                                    {t(
                                                                      "Overdue Tasks"
                                                                    )}
                                                                  </span>
                                                                ),
                                                                id: "tasks",
                                                                content: (
                                                                  <>
                                                                    {project.tasks?.items?.filter(
                                                                      (task) =>
                                                                        task.stage ===
                                                                          project.stage &&
                                                                        task.deadline &&
                                                                        !task.done &&
                                                                        isDeadlinePassed(
                                                                          task.deadline
                                                                        )
                                                                    ).length ? (
                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                        }}
                                                                      >
                                                                        <b>
                                                                          {t(
                                                                            "Overdue task"
                                                                          )}
                                                                        </b>
                                                                        :{" "}
                                                                        {overdueTask(
                                                                          project.tasks?.items
                                                                            ?.filter(
                                                                              (
                                                                                task
                                                                              ) =>
                                                                                task.stage ===
                                                                                  project.stage &&
                                                                                task.deadline &&
                                                                                !task.done &&
                                                                                isDeadlinePassed(
                                                                                  task.deadline
                                                                                )
                                                                            )
                                                                            .sort(
                                                                              (
                                                                                a,
                                                                                b
                                                                              ) => {
                                                                                if (
                                                                                  a.deadline &&
                                                                                  b.deadline
                                                                                ) {
                                                                                  return (
                                                                                    a.deadline -
                                                                                    b.deadline
                                                                                  );
                                                                                } else {
                                                                                  return a.deadline
                                                                                    ? -1
                                                                                    : 1;
                                                                                }
                                                                              }
                                                                            )[0]
                                                                        )}
                                                                      </div>
                                                                    ) : (
                                                                      <i
                                                                        style={{
                                                                          color:
                                                                            "gray",
                                                                        }}
                                                                      >
                                                                        {t(
                                                                          "No tasks available"
                                                                        )}
                                                                        .
                                                                      </i>
                                                                    )}
                                                                  </>
                                                                ),
                                                              },
                                                            ]}
                                                          />
                                                        </SpaceBetween>
                                                      </>
                                                    }
                                                  >
                                                    <NotificationsActiveIcon
                                                      style={{
                                                        color: "#c62828",
                                                      }}
                                                    />
                                                  </CloudscapePopover>
                                                ) : (
                                                  <CloudscapePopover
                                                    position="top"
                                                    dismissButton
                                                    size="large"
                                                    triggerType="custom"
                                                    header={t("Notifications")}
                                                    content={
                                                      <>
                                                        <i
                                                          style={{
                                                            color: "gray",
                                                          }}
                                                        >
                                                          {t(
                                                            "No new notifications"
                                                          )}
                                                          .
                                                        </i>
                                                        <Divider />
                                                        <SpaceBetween size="s">
                                                          <Tabs
                                                            tabs={[
                                                              // Overdue documents
                                                              {
                                                                label: (
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "0.8em",
                                                                    }}
                                                                  >
                                                                    {t(
                                                                      "Next Document to Submit"
                                                                    )}
                                                                  </span>
                                                                ),
                                                                id: "documents",
                                                                content: project
                                                                  .documents
                                                                  ?.items
                                                                  .length ? (
                                                                  <>
                                                                    {nextDocument(
                                                                      project.documents?.items
                                                                        ?.filter(
                                                                          (d) =>
                                                                            d.stage ===
                                                                            project.stage
                                                                        )
                                                                        ?.sort(
                                                                          (
                                                                            a,
                                                                            b
                                                                          ) =>
                                                                            new Date(
                                                                              a.createdAt
                                                                            ) -
                                                                            new Date(
                                                                              b.createdAt
                                                                            )
                                                                        )
                                                                        .sort(
                                                                          (
                                                                            a,
                                                                            b
                                                                          ) => {
                                                                            if (
                                                                              a.deadline &&
                                                                              b.deadline
                                                                            ) {
                                                                              return (
                                                                                a.deadline -
                                                                                b.deadline
                                                                              );
                                                                            } else {
                                                                              return a.deadline
                                                                                ? -1
                                                                                : 1;
                                                                            }
                                                                          }
                                                                        )[0]
                                                                    )}
                                                                  </>
                                                                ) : (
                                                                  <i
                                                                    style={{
                                                                      color:
                                                                        "gray",
                                                                    }}
                                                                  >
                                                                    {t(
                                                                      "No documents available"
                                                                    )}
                                                                    .
                                                                  </i>
                                                                ),
                                                              },
                                                              // Overdue tasks
                                                              {
                                                                label: (
                                                                  <span
                                                                    style={{
                                                                      fontSize:
                                                                        "0.8em",
                                                                    }}
                                                                  >
                                                                    {t(
                                                                      "Overdue Tasks"
                                                                    )}
                                                                  </span>
                                                                ),
                                                                id: "tasks",
                                                                content: (
                                                                  <>
                                                                    {project.tasks?.items?.filter(
                                                                      (task) =>
                                                                        task.stage ===
                                                                          project.stage &&
                                                                        task.deadline &&
                                                                        !task.done &&
                                                                        isDeadlinePassed(
                                                                          task.deadline
                                                                        )
                                                                    ).length ? (
                                                                      <div
                                                                        style={{
                                                                          display:
                                                                            "flex",
                                                                        }}
                                                                      >
                                                                        <b>
                                                                          {t(
                                                                            "Overdue task"
                                                                          )}
                                                                        </b>
                                                                        :{" "}
                                                                        {overdueTask(
                                                                          project.tasks?.items
                                                                            ?.filter(
                                                                              (
                                                                                task
                                                                              ) =>
                                                                                task.stage ===
                                                                                  project.stage &&
                                                                                task.deadline &&
                                                                                !task.done &&
                                                                                isDeadlinePassed(
                                                                                  task.deadline
                                                                                )
                                                                            )
                                                                            .sort(
                                                                              (
                                                                                a,
                                                                                b
                                                                              ) => {
                                                                                if (
                                                                                  a.deadline &&
                                                                                  b.deadline
                                                                                ) {
                                                                                  return (
                                                                                    a.deadline -
                                                                                    b.deadline
                                                                                  );
                                                                                } else {
                                                                                  return a.deadline
                                                                                    ? -1
                                                                                    : 1;
                                                                                }
                                                                              }
                                                                            )[0]
                                                                        )}
                                                                      </div>
                                                                    ) : (
                                                                      <i
                                                                        style={{
                                                                          color:
                                                                            "gray",
                                                                        }}
                                                                      >
                                                                        {t(
                                                                          "No tasks available"
                                                                        )}
                                                                        .
                                                                      </i>
                                                                    )}
                                                                  </>
                                                                ),
                                                              },
                                                            ]}
                                                          />
                                                        </SpaceBetween>
                                                      </>
                                                    }
                                                  >
                                                    <NotificationsNoneIcon
                                                      style={{
                                                        color: primaryBlue,
                                                      }}
                                                    />
                                                  </CloudscapePopover>
                                                )}
                                              </div>
                                            </SpaceBetween>

                                            {isPageLoading ? (
                                              <p
                                                style={{
                                                  textAlign: "center",
                                                }}
                                              >
                                                {t("Loading")}...
                                              </p>
                                            ) : project?.startDate &&
                                              project?.startDate <=
                                                currentDate ? (
                                              <ProjectTimeline
                                                project={project}
                                                t={t}
                                              />
                                            ) : (
                                              <PrePlanningPhase
                                                startDate={project?.startDate}
                                                role={userDetails.role}
                                                t={t}
                                              />
                                            )}
                                          </Box>

                                          <div
                                            style={{
                                              marginRight: 30,
                                              float: "right",
                                              display: "flex",
                                              marginTop: "20%",
                                            }}
                                          >
                                            {!project?.mainAgent ? (
                                              <i style={{ color: "gray" }}>
                                                {t("Not yet assigned")}
                                              </i>
                                            ) : (
                                              <CloudscapePopover
                                                position="top"
                                                dismissButton={false}
                                                size="medium"
                                                triggerType="custom"
                                                header={
                                                  project?.mainAgent
                                                    ?.given_name +
                                                  " " +
                                                  project?.mainAgent
                                                    ?.family_name
                                                }
                                                content={
                                                  <SpaceBetween size="s">
                                                    <Link>
                                                      {project.mainAgent?.email}
                                                    </Link>
                                                    <span>
                                                      {
                                                        project.mainAgent
                                                          ?.phone_number
                                                      }
                                                    </span>
                                                    <span>
                                                      {project.mainAgent
                                                        ?.role === "agents"
                                                        ? "Agent"
                                                        : "Developer"}
                                                    </span>
                                                    <span>
                                                      {t("Municipality")}:{" "}
                                                      {MapCodeToLabel(
                                                        project.mainAgent
                                                          ?.municipality
                                                      ) || "None"}
                                                    </span>
                                                  </SpaceBetween>
                                                }
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <AccountCircleIcon
                                                    style={{ marginRight: 5 }}
                                                  />
                                                  <b
                                                    style={{
                                                      color: primaryBlue,
                                                    }}
                                                  >
                                                    {project.mainAgent
                                                      ?.given_name +
                                                      " " +
                                                      project.mainAgent
                                                        ?.family_name}
                                                  </b>
                                                </div>
                                              </CloudscapePopover>
                                            )}
                                          </div>
                                        </Grid>

                                        {userProjects.length >= 2 && (
                                          <Divider />
                                        )}
                                      </div>
                                    ))
                                  )
                                ) : selectedProjectTab === "inMunicipality" ? (
                                  <>
                                    {!domainProjects ? (
                                      <p>No projects found.</p>
                                    ) : (
                                      domainProjects?.map((project) => (
                                        <Box key={project.id}>
                                          <Grid
                                            gridDefinition={[
                                              { colspan: 9 },
                                              { colspan: 3 },
                                            ]}
                                          >
                                            <Box>
                                              <Link
                                                href={"/project/" + project.id}
                                              >
                                                <h3>
                                                  {project?.name +
                                                    " " +
                                                    t("by") +
                                                    " " +
                                                    project?.contact_organization}{" "}
                                                  (
                                                  {
                                                    project?.mainDeveloper
                                                      .given_name
                                                  }{" "}
                                                  {
                                                    project?.mainDeveloper
                                                      .family_name
                                                  }
                                                  )
                                                </h3>
                                              </Link>
                                              {isPageLoading ? (
                                                <p
                                                  style={{
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {t("Loading")}...
                                                </p>
                                              ) : project?.startDate &&
                                                project?.startDate <=
                                                  currentDate ? (
                                                <ProjectTimeline
                                                  project={project}
                                                  t={t}
                                                />
                                              ) : (
                                                <PrePlanningPhase
                                                  startDate={project?.startDate}
                                                  role={userDetails.role}
                                                  t={t}
                                                />
                                              )}
                                            </Box>
                                            {project?.mainAgent && (
                                              <CloudscapePopover
                                                position="top"
                                                size="medium"
                                                triggerType="custom"
                                                header={
                                                  project.mainAgent
                                                    ?.given_name +
                                                  " " +
                                                  project.mainAgent?.family_name
                                                }
                                                content={
                                                  <SpaceBetween size="s">
                                                    <Link>
                                                      {project.mainAgent?.email}
                                                    </Link>
                                                    <span>
                                                      {
                                                        project.mainAgent
                                                          ?.phone_number
                                                      }
                                                    </span>
                                                    <span>
                                                      {project.mainAgent
                                                        ?.role === "agents"
                                                        ? "Agent"
                                                        : "Developer"}
                                                    </span>
                                                    <span>
                                                      {t("Municipality")}:{" "}
                                                      {MapCodeToLabel(
                                                        project.mainAgent
                                                          ?.municipality
                                                      ) || "None"}
                                                    </span>
                                                  </SpaceBetween>
                                                }
                                              >
                                                <div
                                                  style={{
                                                    float: "right",
                                                    cursor: "pointer",
                                                    display: "flex",
                                                    marginTop: "40%",
                                                    marginLeft: 30,
                                                  }}
                                                >
                                                  <AccountCircleIcon
                                                    style={{ marginRight: 5 }}
                                                  />
                                                  <b
                                                    style={{
                                                      color: primaryBlue,
                                                      marginTop: 0,
                                                    }}
                                                  >
                                                    {project.mainAgent
                                                      ?.given_name +
                                                      " " +
                                                      project.mainAgent
                                                        ?.family_name}
                                                  </b>
                                                </div>
                                              </CloudscapePopover>
                                            )}
                                          </Grid>
                                          <Divider />
                                        </Box>
                                      ))
                                    )}
                                  </>
                                ) : selectedProjectTab === "unassigned" ? (
                                  unassignedProjects.map((project) => (
                                    <Box key={project.id}>
                                      <Grid
                                        gridDefinition={[
                                          { colspan: 9 },
                                          { colspan: 3 },
                                        ]}
                                      >
                                        <Box>
                                          <Link href={"/project/" + project.id}>
                                            <h3>
                                              {project.name +
                                                " " +
                                                t("by") +
                                                " " +
                                                project?.contact_organization}{" "}
                                              (
                                              {
                                                project?.mainDeveloper
                                                  .given_name
                                              }{" "}
                                              {
                                                project?.mainDeveloper
                                                  .family_name
                                              }
                                              )
                                            </h3>
                                          </Link>
                                          {isPageLoading ? (
                                            <p style={{ textAlign: "center" }}>
                                              {t("Loading")}...
                                            </p>
                                          ) : project?.startDate &&
                                            project?.startDate <=
                                              currentDate ? (
                                            <ProjectTimeline
                                              project={project}
                                              t={t}
                                            />
                                          ) : (
                                            <PrePlanningPhase
                                              startDate={project?.startDate}
                                              role={userDetails.role}
                                              t={t}
                                            />
                                          )}
                                        </Box>
                                        {
                                          <div
                                            style={{
                                              marginRight: 30,
                                              float: "right",
                                              display: "flex",
                                              marginTop: "20%",
                                            }}
                                          >
                                            <Button
                                              variant="primary"
                                              onClick={() =>
                                                assignProjectToMe(project.id)
                                              }
                                              disabled={agentAssignmentPending}
                                            >
                                              {t("Assign Project to Me")}
                                            </Button>
                                          </div>
                                        }
                                      </Grid>
                                      <Divider />
                                    </Box>
                                  ))
                                ) : (
                                  // Closed projects
                                  <>
                                    {!closedProjects ? (
                                      <p>No projects found.</p>
                                    ) : (
                                      closedProjects.map((project, index) => (
                                        <div key={project.id}>
                                          <Box>
                                            <Grid
                                              gridDefinition={[
                                                { colspan: 9 },
                                                { colspan: 2 },
                                              ]}
                                            >
                                              <Box>
                                                <Link
                                                  href={
                                                    "/project/" + project.id
                                                  }
                                                >
                                                  <h3>
                                                    {project.name +
                                                      " " +
                                                      t("by") +
                                                      " " +
                                                      project?.contact_organization}{" "}
                                                    (
                                                    {
                                                      project?.mainDeveloper
                                                        .given_name
                                                    }{" "}
                                                    {
                                                      project?.mainDeveloper
                                                        .family_name
                                                    }
                                                    )
                                                  </h3>
                                                </Link>

                                                {isPageLoading ? (
                                                  <p
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {t("Loading")}...
                                                  </p>
                                                ) : project?.startDate &&
                                                  project?.startDate <=
                                                    currentDate ? (
                                                  <ProjectTimeline
                                                    project={project}
                                                    t={t}
                                                  />
                                                ) : (
                                                  <PrePlanningPhase
                                                    startDate={
                                                      project?.startDate
                                                    }
                                                    role={userDetails.role}
                                                    t={t}
                                                  />
                                                )}
                                              </Box>
                                              {project?.mainAgent && (
                                                <CloudscapePopover
                                                  position="top"
                                                  size="medium"
                                                  triggerType="custom"
                                                  header={
                                                    project.mainAgent
                                                      ?.given_name +
                                                    project.mainAgent
                                                      ?.family_name
                                                  }
                                                  content={
                                                    <SpaceBetween size="s">
                                                      <Link>
                                                        {
                                                          project.mainAgent
                                                            ?.email
                                                        }
                                                      </Link>
                                                      <span>
                                                        {
                                                          project.mainAgent
                                                            ?.phone_number
                                                        }
                                                      </span>
                                                      <span>
                                                        {project.mainAgent
                                                          ?.role === "agents"
                                                          ? "Agent"
                                                          : "Developer"}
                                                      </span>
                                                      <span>
                                                        {t("Municipality")}:{" "}
                                                        {MapCodeToLabel(
                                                          project.mainAgent
                                                            ?.municipality
                                                        ) || "None"}
                                                      </span>
                                                    </SpaceBetween>
                                                  }
                                                >
                                                  <div
                                                    style={{
                                                      float: "right",
                                                      display: "flex",
                                                      cursor: "pointer",
                                                      marginTop: "35%",
                                                      marginLeft: 30,
                                                    }}
                                                  >
                                                    <AccountCircleIcon
                                                      style={{ marginRight: 5 }}
                                                    />
                                                    <b
                                                      style={{
                                                        color: primaryBlue,
                                                        marginTop: 0,
                                                      }}
                                                    >
                                                      {project.mainAgent
                                                        ?.given_name +
                                                        " " +
                                                        project.mainAgent
                                                          ?.family_name}
                                                    </b>
                                                  </div>
                                                </CloudscapePopover>
                                              )}
                                              <Tooltip
                                                title={t(
                                                  "Download all project files"
                                                )}
                                              >
                                                <IconButton
                                                  aria-label={t(
                                                    "Download all project files"
                                                  )}
                                                  style={{ marginTop: 45 }}
                                                  onClick={() => {
                                                    const myDownloadModal = {
                                                      ...downloadModal,
                                                    };
                                                    myDownloadModal[
                                                      project.id
                                                    ] = true;
                                                    showDownloadModal(
                                                      myDownloadModal
                                                    );
                                                  }}
                                                >
                                                  <DownloadForOfflineIcon
                                                    style={{
                                                      color: primaryBlue,
                                                      fontSize: "1.4em",
                                                    }}
                                                  />
                                                </IconButton>
                                              </Tooltip>
                                            </Grid>
                                            <Divider />
                                          </Box>
                                          {/* ============= Download all project files modal ============= */}
                                          <Modal
                                            size="large"
                                            onDismiss={() => {
                                              const myDownloadModal = {
                                                ...downloadModal,
                                              };
                                              myDownloadModal[
                                                project.id
                                              ] = false;
                                              showDownloadModal(
                                                myDownloadModal
                                              );
                                            }}
                                            visible={downloadModal[project.id]}
                                            header={t(
                                              "Download all project files"
                                            )}
                                          >
                                            {t(
                                              "Please choose what you would like to download"
                                            )}
                                            :
                                            <Box padding="l">
                                              <Grid
                                                gridDefinition={[
                                                  { colspan: 3 },
                                                  { colspan: 3 },
                                                  { colspan: 3 },
                                                  { colspan: 3 },
                                                ]}
                                              >
                                                {/* ------------------- Project summary -------------- */}
                                                <CSVLink
                                                  separator={";"}
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                  filename={`${
                                                    project?.name
                                                  }-${t(
                                                    "Project Summary"
                                                  )}-${new Date().toLocaleDateString()}.csv`}
                                                  data={getProjectDetails(
                                                    project
                                                  )}
                                                  headers={
                                                    ProjectDetailsHeaders
                                                  }
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <IconButton
                                                      aria-label={t(
                                                        "Project Summary"
                                                      )}
                                                      disabled={
                                                        !project?.comments
                                                          ?.items?.length
                                                      }
                                                      style={{
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                      }}
                                                    >
                                                      <FormatListBulletedIcon
                                                        style={{
                                                          color: primaryBlue,
                                                          fontSize: "1.6em",
                                                        }}
                                                      />
                                                    </IconButton>
                                                    <b
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {t("Project Summary")}
                                                    </b>
                                                  </div>
                                                </CSVLink>
                                                {/* ------------------- All project comments ------------------ */}
                                                <CSVLink
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                  filename={`${
                                                    project.name
                                                  }-${t(
                                                    "Comments"
                                                  )}-${new Date().toLocaleDateString()}.csv`}
                                                  data={
                                                    project?.comments?.items?.map(
                                                      (c) => ({
                                                        comment: c.comment,
                                                        date: c.createdAt,
                                                        by: `${c.persona?.family_name}, ${c.persona?.given_name} <${c.persona?.email}>`,
                                                      })
                                                    ) || ""
                                                  }
                                                  headers={CommentsHeaders}
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <IconButton
                                                      aria-label={t(
                                                        "All project comments"
                                                      )}
                                                      disabled={
                                                        !project?.comments
                                                          ?.items?.length
                                                      }
                                                      style={{
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                      }}
                                                    >
                                                      <TopicIcon
                                                        style={{
                                                          color: primaryBlue,
                                                          fontSize: "1.6em",
                                                        }}
                                                      />
                                                    </IconButton>
                                                    <b
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {t(
                                                        "All project comments"
                                                      )}
                                                    </b>
                                                  </div>
                                                </CSVLink>

                                                {/* ------------------- All document comments ------------------ */}
                                                <CSVLink
                                                  style={{
                                                    textDecoration: "none",
                                                    color: "black",
                                                  }}
                                                  filename={`${
                                                    project.name
                                                  }-${t(
                                                    "Document Comments"
                                                  )}-${new Date().toLocaleDateString()}.csv`}
                                                  data={
                                                    projectDocumentComments[
                                                      project.id
                                                    ]
                                                  }
                                                  headers={
                                                    DocumentCommentsHeaders
                                                  }
                                                >
                                                  <div
                                                    style={{
                                                      display: "flex",
                                                      flexDirection: "column",
                                                      justifyContent: "center",
                                                    }}
                                                  >
                                                    <IconButton
                                                      aria-label={t(
                                                        "All document comments"
                                                      )}
                                                      style={{
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                      }}
                                                    >
                                                      <DescriptionIcon
                                                        style={{
                                                          color: primaryBlue,
                                                          fontSize: "1.6em",
                                                        }}
                                                      />
                                                    </IconButton>
                                                    <b
                                                      style={{
                                                        textAlign: "center",
                                                      }}
                                                    >
                                                      {t(
                                                        "All document comments"
                                                      )}
                                                    </b>
                                                  </div>
                                                </CSVLink>

                                                {/* ------------------- All document attachments ------------------ */}
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "center",
                                                  }}
                                                >
                                                  <IconButton
                                                    aria-label={t(
                                                      "All document attachments (all versions)"
                                                    )}
                                                    style={{
                                                      marginLeft: "auto",
                                                      marginRight: "auto",
                                                    }}
                                                    onClick={() =>
                                                      downloadAllFilesFromS3(
                                                        project.id,
                                                        project.name
                                                      )
                                                    }
                                                  >
                                                    <AttachFileIcon
                                                      style={{
                                                        color: primaryBlue,
                                                        fontSize: "1.6em",
                                                      }}
                                                    />
                                                  </IconButton>
                                                  <b
                                                    style={{
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {t(
                                                      "All document attachments (all versions)"
                                                    )}
                                                  </b>
                                                  {!documentHasFiles && (
                                                    <i
                                                      style={{
                                                        paddingTop: 10,
                                                        fontSize: "0.9em",
                                                        textAlign: "center",
                                                        color: "gray",
                                                      }}
                                                    >
                                                      {t(
                                                        "No attachments found"
                                                      )}
                                                      .
                                                    </i>
                                                  )}
                                                </div>
                                              </Grid>
                                            </Box>
                                          </Modal>
                                        </div>
                                      ))
                                    )}
                                  </>
                                )}
                              </div>
                            </>
                          ),
                        },
                        {
                          label: t("My Tasks"),
                          id: "myTasks",
                          content: (
                            <>
                              <h2>{t("My Tasks")}</h2>
                              <div style={{ height: 15 }} />
                              <SegmentedControl
                                ariaLabelledby="My Tasks Segmented Control"
                                selectedId={selectedTaskId}
                                onChange={({ detail }) =>
                                  setSelectedTaskId(detail.selectedId)
                                }
                                label="My Tasks segmented control"
                                options={[
                                  { text: t("This Week"), id: "thisWeek" },
                                  {
                                    text: t("Next Week"),
                                    id: "nextWeek",
                                  },
                                  {
                                    text: t("Overall"),
                                    id: "overall",
                                  },
                                ]}
                              />
                              {selectedTaskId === "thisWeek" ? (
                                <div style={{ marginTop: 40 }}>
                                  <Table
                                    variant="embedded"
                                    columnDefinitions={[
                                      {
                                        id: "taskName",
                                        header: t("Task Name"),
                                        cell: (e) => (
                                          <a
                                            style={{
                                              color: primaryBlue,
                                              textDecoration: "none",
                                            }}
                                            href={`project/${e?.project.id}/tasks`}
                                          >
                                            {t(e?.title)}
                                          </a>
                                        ),
                                        sortingField: "taskName",
                                      },
                                      {
                                        id: "projectName",
                                        header: t("Project Name"),
                                        cell: (e) => (
                                          <a
                                            style={{
                                              color: primaryBlue,
                                              textDecoration: "none",
                                            }}
                                            href={`project/${e?.project.id}`}
                                          >
                                            {e?.project?.name}
                                          </a>
                                        ),
                                        sortingField: "projectName",
                                      },
                                      {
                                        id: "projectPhase",
                                        header: t("Project Phase"),
                                        cell: (e) => t(e?.project?.stage),
                                        sortingField: "projectPhase",
                                      },
                                      {
                                        id: "dueDate",
                                        header: t("Due Date"),
                                        cell: (e) => (
                                          <span
                                            style={{
                                              fontWeight:
                                                isDeadlinePassed(e.deadline) &&
                                                "bold",
                                              color:
                                                isDeadlinePassed(e.deadline) &&
                                                warningOrange,
                                            }}
                                          >
                                            {calculatePriority(
                                              e.deadline,
                                              true
                                            ) || "-"}
                                          </span>
                                        ),
                                        sortingField: "dueDate",
                                      },
                                      {
                                        id: "days2Finish",
                                        header: t("Days to Finish"),
                                        cell: (e) => (
                                          <span
                                            style={{
                                              fontWeight:
                                                isDeadlinePassed(e.deadline) &&
                                                "bold",
                                              color:
                                                isDeadlinePassed(e.deadline) &&
                                                warningOrange,
                                            }}
                                          >
                                            {isDeadlinePassed(e.deadline)
                                              ? t("Delayed")
                                              : e.deadline
                                              ? diffDays(e.deadline)
                                              : "-"}
                                          </span>
                                        ),
                                        sortingField: "days2Finish",
                                      },
                                    ]}
                                    items={thisWeeksTasks}
                                    loadingText={t("Loading resources")}
                                    trackBy="taskName"
                                    visibleColumns={[
                                      "taskName",
                                      "projectName",
                                      "projectPhase",
                                      "dueDate",
                                      "days2Finish",
                                    ]}
                                    empty={
                                      <Box textAlign="center" color="inherit">
                                        <b>{t("No resources")}</b>
                                        <Box
                                          padding={{ bottom: "s" }}
                                          variant="p"
                                          color="inherit"
                                        >
                                          {t("No resources to display")}.
                                        </Box>
                                      </Box>
                                    }
                                  />
                                </div>
                              ) : selectedTaskId === "nextWeek" ? (
                                <div style={{ marginTop: 40 }}>
                                  <Table
                                    variant="embedded"
                                    columnDefinitions={[
                                      {
                                        id: "taskName",
                                        header: t("Task Name"),
                                        cell: (e) => (
                                          <a
                                            style={{
                                              color: primaryBlue,
                                              textDecoration: "none",
                                            }}
                                            href={`project/${e?.project.id}/tasks`}
                                          >
                                            {t(e?.title)}
                                          </a>
                                        ),
                                        sortingField: "taskName",
                                      },
                                      {
                                        id: "projectName",
                                        header: t("Project Name"),
                                        cell: (e) => (
                                          <a
                                            style={{
                                              color: primaryBlue,
                                              textDecoration: "none",
                                            }}
                                            href={`project/${e?.project.id}`}
                                          >
                                            {e?.project?.name}
                                          </a>
                                        ),
                                        sortingField: "projectName",
                                      },
                                      {
                                        id: "projectPhase",
                                        header: t("Project Phase"),
                                        sortingField: "projectPhase",
                                        cell: (e) => t(e?.project?.stage),
                                      },
                                      {
                                        id: "dueDate",
                                        header: t("Due Date"),
                                        sortingField: "dueDate",
                                        cell: (e) => (
                                          <span
                                            style={{
                                              fontWeight:
                                                isDeadlinePassed(e.deadline) &&
                                                "bold",
                                              color:
                                                isDeadlinePassed(e.deadline) &&
                                                warningOrange,
                                            }}
                                          >
                                            {calculatePriority(
                                              e.deadline,
                                              true
                                            ) || "-"}
                                          </span>
                                        ),
                                      },
                                      {
                                        id: "days2Finish",
                                        header: t("Days to Finish"),
                                        sortingField: "days2Finish",
                                        cell: (e) => (
                                          <span
                                            style={{
                                              fontWeight:
                                                isDeadlinePassed(e.deadline) &&
                                                "bold",
                                              color:
                                                isDeadlinePassed(e.deadline) &&
                                                warningOrange,
                                            }}
                                          >
                                            {isDeadlinePassed(e.deadline)
                                              ? t("Delayed")
                                              : e.deadline
                                              ? diffDays(e.deadline)
                                              : "-"}
                                          </span>
                                        ),
                                      },
                                    ]}
                                    items={nextWeeksTasks}
                                    loadingText={t("Loading resources")}
                                    trackBy="taskName"
                                    visibleColumns={[
                                      "taskName",
                                      "projectName",
                                      "projectPhase",
                                      "dueDate",
                                      "days2Finish",
                                    ]}
                                    empty={
                                      <Box textAlign="center" color="inherit">
                                        <b>{t("No resources")}</b>
                                        <Box
                                          padding={{ bottom: "s" }}
                                          variant="p"
                                          color="inherit"
                                        >
                                          {t("No resources to display")}.
                                        </Box>
                                      </Box>
                                    }
                                  />
                                </div>
                              ) : (
                                // OVERALL TAB
                                <Table
                                  {...collectionProps}
                                  variant="embedded"
                                  columnDefinitions={[
                                    {
                                      id: "taskName",
                                      header: t("Task Name"),
                                      cell: (e) => (
                                        <a
                                          style={{
                                            color: primaryBlue,
                                            textDecoration: "none",
                                          }}
                                          href={`project/${e?.project.id}/tasks`}
                                        >
                                          {t(e?.title) || "-"}
                                        </a>
                                      ),
                                      sortingField: "taskName",
                                    },
                                    {
                                      id: "projectName",
                                      header: t("Project Name"),
                                      cell: (e) => (
                                        <a
                                          style={{
                                            color: primaryBlue,
                                            textDecoration: "none",
                                          }}
                                          href={`project/${e?.project.id}`}
                                        >
                                          {e?.project?.name || "-"}
                                        </a>
                                      ),
                                      sortingField: "projectName",
                                    },
                                    {
                                      id: "projectPhase",
                                      header: t("Project Phase"),
                                      sortingField: "projectPhase",
                                      ariaLabel: createLabelFunction(
                                        t("Project Phase")
                                      ),
                                      cell: (e) => t(e?.project?.stage) || "-",
                                    },
                                    {
                                      id: "dueDate",
                                      header: t("Due Date"),
                                      sortingField: "dueDate",
                                      ariaLabel: createLabelFunction(
                                        t("Due Date")
                                      ),
                                      cell: (e) => (
                                        <span
                                          style={{
                                            fontWeight:
                                              isDeadlinePassed(e.deadline) &&
                                              "bold",
                                            color:
                                              isDeadlinePassed(e.deadline) &&
                                              warningOrange,
                                          }}
                                        >
                                          {calculatePriority(
                                            e.deadline,
                                            true
                                          ) || "-"}
                                        </span>
                                      ),
                                    },
                                    {
                                      id: "days2Finish",
                                      header: t("Days to Finish"),
                                      sortingField: "days2Finish",
                                      ariaLabel: createLabelFunction(
                                        t("Days to Finish")
                                      ),
                                      cell: (e) => (
                                        <span
                                          style={{
                                            fontWeight:
                                              isDeadlinePassed(e.deadline) &&
                                              "bold",
                                            color:
                                              isDeadlinePassed(e.deadline) &&
                                              warningOrange,
                                          }}
                                        >
                                          {isDeadlinePassed(e.deadline)
                                            ? t("Delayed")
                                            : e.deadline
                                            ? diffDays(e.deadline)
                                            : "-"}
                                        </span>
                                      ),
                                    },
                                  ]}
                                  items={items}
                                  pagination={
                                    <Pagination {...paginationProps} />
                                  }
                                  loadingText="Loading resources"
                                  trackBy="taskName"
                                  visibleColumns={[
                                    "taskName",
                                    "projectName",
                                    "projectPhase",
                                    "dueDate",
                                    "days2Finish",
                                  ]}
                                  empty={
                                    <Box textAlign="center" color="inherit">
                                      <b>{t("No resources")}</b>
                                      <Box
                                        padding={{ bottom: "s" }}
                                        variant="p"
                                        color="inherit"
                                      >
                                        {t("No resources to display")}.
                                      </Box>
                                    </Box>
                                  }
                                />
                              )}
                            </>
                          ),
                        },
                      ]}
                    />
                  </SpaceBetween>
                </ContentLayout>
              }
            />
          </>
        )
      }
    </Authenticator>
  );
}

const ProjectTimeline = ({ project, t }) => {
  // Pre-planning: Documents review
  const [pp_Documents_anchorEl, set_pp_Documents_AnchorEl] = useState(null);
  const open_pp_Documents_Popover = Boolean(pp_Documents_anchorEl);
  // Pre-planning: CC meeting
  const [pp_CityCouncil_anchorEl, set_pp_CityCouncil_AnchorEl] = useState(null);
  const open_pp_CityCouncil_Popover = Boolean(pp_CityCouncil_anchorEl);

  // Initial Public Consultation: Documents review
  const [ipc_Documents_anchorEl, set_ipc_Documents_AnchorEl] = useState(null);
  const open_ipc_Documents_Popover = Boolean(ipc_Documents_anchorEl);
  // Initial Public Consultation: Public consultation
  const [ipc_Consultation_anchorEl, set_ipc_Consultation_AnchorEl] =
    useState(null);
  const open_ipc_Consultation_Popover = Boolean(ipc_Consultation_anchorEl);
  // Initial Public Consultation: CC meeting
  const [ipc_CityCouncil_anchorEl, set_ipc_CityCouncil_AnchorEl] =
    useState(null);
  const open_ipc_CityCouncil_Popover = Boolean(ipc_CityCouncil_anchorEl);

  // Planning Process: Documents review
  const [process_Documents_anchorEl, set_process_Documents_AnchorEl] =
    useState(null);
  const open_process_Documents_Popover = Boolean(process_Documents_anchorEl);
  // Planning Process: CC meeting
  const [process_CityCouncil_anchorEl, set_process_CityCouncil_AnchorEl] =
    useState(null);
  const open_process_CityCouncil_Popover = Boolean(
    process_CityCouncil_anchorEl
  );

  // Public Consultation: Documents review
  const [pc_Documents_anchorEl, set_pc_Documents_AnchorEl] = useState(null);
  const open_pc_Documents_Popover = Boolean(pc_Documents_anchorEl);
  // Public Consultation: Public consultation
  const [pc_Consultation_anchorEl, set_pc_Consultation_AnchorEl] =
    useState(null);
  const open_pc_Consultation_Popover = Boolean(pc_Consultation_anchorEl);
  // Public Consultation: CC meeting
  const [pc_CityCouncil_anchorEl, set_pc_CityCouncil_AnchorEl] = useState(null);
  const open_pc_CityCouncil_Popover = Boolean(pc_CityCouncil_anchorEl);

  // Final Approval: Documents review
  const [fa_Documents_anchorEl, set_fa_Documents_AnchorEl] = useState(null);
  const open_fa_Documents_Popover = Boolean(fa_Documents_anchorEl);
  // Final Approval: CC meeting / Appeal
  const [fa_CityCouncil_anchorEl, set_fa_CityCouncil_AnchorEl] = useState(null);
  const open_fa_CityCouncil_Popover = Boolean(fa_CityCouncil_anchorEl);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* -------------------- PRE-PLANNING --------------------*/}
        <div>
          <h4>{t("Pre-planning")}</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* Documents review */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "a_preplanning",
                    "documents_handover"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  null,
                  null,
                  "a_preplanning",
                  "documents_handover"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_pp_Documents_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_pp_Documents_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_pp_Documents_Popover}
              anchorEl={pp_Documents_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_pp_Documents_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("Documents Review")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(
                    project,
                    "a_preplanning",
                    "documents_handover"
                  ) || ""}
                </span>
              </Typography>
            </Popover>

            {/* City council meeting */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "a_preplanning",
                    "city_council"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "a_preplanning",
                  "documents_handover",
                  "a_preplanning",
                  "city_council"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_pp_CityCouncil_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_pp_CityCouncil_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_pp_CityCouncil_Popover}
              anchorEl={pp_CityCouncil_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_pp_CityCouncil_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("City Council Meeting")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(project, "a_preplanning", "city_council") ||
                    ""}
                </span>
              </Typography>
            </Popover>
          </div>
          {project?.stage === "a_preplanning" && (
            <div
              style={{
                fontSize: "0.85em",
                color: primaryGreen,
                paddingTop: 10,
              }}
            >
              <Icon name="caret-up-filled" />{" "}
              <b>
                {t(project?.projectClosed ? "Last stage" : "Current stage")}
              </b>
            </div>
          )}
        </div>
        {/* -------------------- INITIAL PUBLIC CONSULTATION --------------------*/}
        <div>
          <h4>{t("Initial Public Consultation")}</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* Documents review */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "b_initial_consultation",
                    "documents_handover"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "a_preplanning",
                  "city_council",
                  "b_initial_consultation",
                  "documents_handover"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_ipc_Documents_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_ipc_Documents_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_ipc_Documents_Popover}
              anchorEl={ipc_Documents_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_ipc_Documents_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("Documents Review")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(
                    project,
                    "b_initial_consultation",
                    "documents_handover"
                  ) || ""}
                </span>
              </Typography>
            </Popover>

            {/* Public consultation */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "b_initial_consultation",
                    "public_consultation"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "b_initial_consultation",
                  "documents_handover",
                  "b_initial_consultation",
                  "public_consultation"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_ipc_Consultation_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_ipc_Consultation_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_ipc_Consultation_Popover}
              anchorEl={ipc_Consultation_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_ipc_Consultation_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("Public Consultation")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(
                    project,
                    "b_initial_consultation",
                    "public_consultation"
                  ) || ""}
                </span>
              </Typography>
            </Popover>

            {/* City council meeting */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "b_initial_consultation",
                    "city_council"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "b_initial_consultation",
                  "public_consultation",
                  "b_initial_consultation",
                  "city_council"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_ipc_CityCouncil_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_ipc_CityCouncil_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_ipc_CityCouncil_Popover}
              anchorEl={ipc_CityCouncil_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_ipc_CityCouncil_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("City Council Meeting")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(
                    project,
                    "b_initial_consultation",
                    "city_council"
                  ) || ""}
                </span>
              </Typography>
            </Popover>
          </div>
          {project?.stage === "b_initial_consultation" && (
            <div
              style={{
                fontSize: "0.85em",
                color: primaryGreen,
                paddingTop: 10,
              }}
            >
              <Icon name="caret-up-filled" />{" "}
              <b>
                {t(project?.projectClosed ? "Last stage" : "Current stage")}
              </b>
            </div>
          )}
        </div>
        {/* -------------------- PLANNING PROCESS --------------------*/}
        <div>
          <h4>{t("Planning Process")}</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* Documents review */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "c_planning",
                    "documents_handover"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "b_initial_consultation",
                  "city_council",
                  "c_planning",
                  "documents_handover"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_process_Documents_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_process_Documents_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_process_Documents_Popover}
              anchorEl={process_Documents_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_process_Documents_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("Documents Review")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(
                    project,
                    "c_planning",
                    "documents_handover"
                  ) || ""}
                </span>
              </Typography>
            </Popover>

            {/* City council meeting */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "c_planning",
                    "city_council"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "c_planning",
                  "documents_handover",
                  "c_planning",
                  "city_council"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_process_CityCouncil_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_process_CityCouncil_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_process_CityCouncil_Popover}
              anchorEl={process_CityCouncil_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_process_CityCouncil_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("City Council Meeting")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(project, "c_planning", "city_council") || ""}
                </span>
              </Typography>
            </Popover>
          </div>
          {project?.stage === "c_planning" && (
            <div
              style={{
                fontSize: "0.85em",
                color: primaryGreen,
                paddingTop: 10,
              }}
            >
              <Icon name="caret-up-filled" />{" "}
              <b>
                {t(project?.projectClosed ? "Last stage" : "Current stage")}
              </b>
            </div>
          )}
        </div>
        {/* -------------------- FINAL CONSULTATION --------------------*/}
        <div>
          <h4>{t("Final Public Consultation")}</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* Documents review */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "d_final_consultation",
                    "documents_handover"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "c_planning",
                  "city_council",
                  "d_final_consultation",
                  "documents_handover"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_pc_Documents_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_pc_Documents_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_pc_Documents_Popover}
              anchorEl={pc_Documents_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_pc_Documents_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("Documents Review")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(
                    project,
                    "d_final_consultation",
                    "documents_handover"
                  ) || ""}
                </span>
              </Typography>
            </Popover>

            {/* Public consultation */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "d_final_consultation",
                    "public_consultation"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "d_final_consultation",
                  "documents_handover",
                  "d_final_consultation",
                  "public_consultation"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_pc_Consultation_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_pc_Consultation_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_pc_Consultation_Popover}
              anchorEl={pc_Consultation_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_pc_Consultation_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("Public Consultation")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(
                    project,
                    "d_final_consultation",
                    "public_consultation"
                  ) || ""}
                </span>
              </Typography>
            </Popover>

            {/* City council meeting */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "d_final_consultation",
                    "city_council"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "d_final_consultation",
                  "public_consultation",
                  "d_final_consultation",
                  "city_council"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_pc_CityCouncil_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_pc_CityCouncil_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_pc_CityCouncil_Popover}
              anchorEl={pc_CityCouncil_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              onClose={() => set_pc_CityCouncil_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("City Council Meeting")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(
                    project,
                    "d_final_consultation",
                    "city_council"
                  ) || ""}
                </span>
              </Typography>
            </Popover>
          </div>
          {project?.stage === "d_final_consultation" && (
            <div
              style={{
                fontSize: "0.85em",
                color: primaryGreen,
                paddingTop: 10,
              }}
            >
              <Icon name="caret-up-filled" />{" "}
              <b>
                {t(project?.projectClosed ? "Last stage" : "Current stage")}
              </b>
            </div>
          )}
        </div>
        {/* -------------------- APPROVAL --------------------*/}
        <div>
          <h4>{t("Final Approval")}</h4>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* Documents review */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "e_approval",
                    "documents_handover"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "d_final_consultation",
                  "city_council",
                  "e_approval",
                  "documents_handover"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_fa_Documents_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_fa_Documents_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_fa_Documents_Popover}
              anchorEl={fa_Documents_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_fa_Documents_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("Documents Review")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(
                    project,
                    "e_approval",
                    "documents_handover"
                  ) || ""}
                </span>
              </Typography>
            </Popover>

            {/* Appeal */}
            <LinearProgress
              sx={{
                height: 10,
                width: "100%",
                borderRadius: 0,
                [`&.${linearProgressClasses.colorPrimary}`]: {
                  backgroundColor: lightGray,
                  borderRight: "1px solid",
                },
                // progress colour
                [`& .${linearProgressClasses.bar}`]: {
                  borderRadius: 0,
                  backgroundColor: getProgressColor(
                    project,
                    "e_approval",
                    "city_council"
                  ),
                },
              }}
              variant="determinate"
              value={
                getDeadlineAsNumber(
                  project,
                  "e_approval",
                  "documents_handover",
                  "e_approval",
                  "city_council"
                ) || 0
              }
              onMouseEnter={(event) =>
                set_fa_CityCouncil_AnchorEl(event.currentTarget)
              }
              onMouseLeave={() => set_fa_CityCouncil_AnchorEl(null)}
            />
            <Popover
              id="pre-planning-documents-review-popover"
              disableRestoreFocus
              sx={{
                pointerEvents: "none",
              }}
              open={open_fa_CityCouncil_Popover}
              anchorEl={fa_CityCouncil_anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              onClose={() => set_fa_CityCouncil_AnchorEl(null)}
            >
              <Typography sx={{ p: 1 }}>
                <b style={{ fontSize: "0.8em" }}>{t("Appeal")}</b>
                <br />
                <span style={{ fontSize: "0.8em" }}>
                  {getDeadlineDate(project, "e_approval", "city_council") || ""}
                </span>
              </Typography>
            </Popover>
          </div>
          {project?.stage === "e_approval" && (
            <div
              style={{
                fontSize: "0.85em",
                color: primaryGreen,
                paddingTop: 10,
              }}
            >
              <Icon name="caret-up-filled" />{" "}
              <b>
                {t(project?.projectClosed ? "Last stage" : "Current stage")}
              </b>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const PrePlanningPhase = ({ startDate, role, t }) => {
  return (
    <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
      <div>
        <h4>{t("Pre-planning")}</h4>
        {!startDate
          ? t("Start date not set yet.")
          : startDate > currentDate && (
              <span>
                <p style={{ fontSize: "0.9em" }}>
                  {t("Project will start on")} <b>{startDate}</b>
                </p>
              </span>
            )}
      </div>
      <div>
        <Alert
          statusIconAriaLabel="Info"
          header={
            <span style={{ fontSize: "0.9em" }}>
              {t("You are in the Pre-Planning phase")}
            </span>
          }
        >
          <span style={{ fontSize: "0.9em" }}>
            {t(
              "Start date will be set once all the documents and have been reviewed"
            )}
            .
          </span>
        </Alert>
      </div>
    </Grid>
  );
};
