import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../i18n";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Box,
  BreadcrumbGroup,
  Container,
} from "@cloudscape-design/components";
import NavigationBar from "./NavigationBar";

export default function DataPrivacy() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "EasyPermits® Data Privacy";
  }, []);

  return (
    <>
      <NavigationBar />
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={
          <ContentLayout
            header={
              <SpaceBetween size="xxl">
                <div />
                <BreadcrumbGroup
                  ariaLabel="Breadcrumbs"
                  items={[
                    { text: t("Home"), href: "/map" },
                    {
                      text: t("Data Privacy"),
                      href: "#",
                    },
                  ]}
                />
                <Header variant="h1">{t("Data Privacy")}</Header>
                <div />
              </SpaceBetween>
            }
          >
            <Container fitHeight>
              <Box textAlign="center">
                <h2>
                  {t("WindEurope Data Privacy Statement for EasyPermits®")}
                </h2>
              </Box>
              <Box>
                <h3>1. {t("General")}</h3>
                <p>
                  1.1.{" "}
                  {t(
                    "This Privacy Statement (“Privacy Statement”) explains how and for what purpose WindEurope will process certain personal data belonging to the authorized users of this tool (”Tool”) when they access and use it"
                  )}
                  .
                </p>
                <p>
                  1.2.{" "}
                  {t(
                    "Why are you interacting with WindEurope in the context of EasyPermits®"
                  )}
                  :
                  <ol>
                    <li>
                      {t(
                        "WindEurope provides access to this Tool to an entity, organization, or business you are affiliated with (“Your Organization”)"
                      )}
                      .
                    </li>
                    <li>
                      {t(
                        "You have provided EasyPermits® and therewith WindEurope with your contact details and were designated as an authorized user of the Tool. If you have any doubts or queries relating to why your personal data has been shared with WindEurope or you have been selected to access and use the Tool, you should contact Your Organization first"
                      )}
                      .
                    </li>
                  </ol>
                </p>
              </Box>
              <Box>
                <h3>2. {t("Legal Framework")}</h3>
                <p>
                  2.1.{" "}
                  {t(
                    "This privacy policy is subject to privacy legislation, i.e."
                  )}
                  :
                </p>
                <ol>
                  <li>
                    {t(
                      "the Belgian Data Protection Act of 8th December 1992 on the protection of privacy in relation to the processing of personal data (as amended) (the “Privacy Act”); and/or"
                    )}
                  </li>
                  <li>
                    {t(
                      "Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons regarding the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) (“GDPR”); and/or"
                    )}
                  </li>
                  <li>
                    {t(
                      "All other applicable legislation regarding the protection of privacy and the processing of personal data"
                    )}
                    ;
                  </li>
                </ol>
              </Box>
              <Box>
                <h3>3. {t("Purpose")}</h3>
                <p>
                  3.1.{" "}
                  {t(
                    "WindEurope can process personal data in accordance with the Privacy Legislation and this privacy policy to"
                  )}
                  :
                </p>
                <b>{t("Account data / login credentials")}</b>
                <ol>
                  <li>{t("Creating your account in the Tool")}.</li>
                  <li>{t("Enabling you to access and use the Tool")}.</li>
                  <li>
                    {t(
                      "Exercising or defending WindEurope's rights in the context of legal claims/disputes."
                    )}
                  </li>
                </ol>
              </Box>
              <Box>
                <h3>4. {t("Legal basis and Applicability")}</h3>
                <p>
                  4.1.{" "}
                  {t(
                    "WindEurope has the following legal grounds for the processing of personal data"
                  )}
                  :
                </p>
                <ol>
                  <li>
                    {t(
                      "your free, specific, informed and unambiguous consent to WindEurope, its partners (e.g., subcontractors, partners or contracting parties) to process your personal data. You can give your consent by any means, such as, but not limited to mail, email, an online or offline consent form, a membership form; and/or"
                    )}
                  </li>
                  <li>{t("a legal obligation; and/ or")}</li>
                  <li>{t("a legitimate interest of WindEurope")}.</li>
                </ol>
                <p>
                  4.2.{" "}
                  {t(
                    "Providing your personal data to WindEurope, implies that you"
                  )}
                  :
                </p>
                <ol>
                  <li>
                    {t(
                      "declare that you are fully informed about this privacy policy and that you fully accept this privacy policy without any reservation; and"
                    )}
                  </li>
                  <li>
                    {t(
                      "give your free, specific, informed and unambiguous consent to WindEurope to process or have processed your personal data in accordance with this privacy policy and the Privacy Legislation."
                    )}
                  </li>
                </ol>
                <p>
                  4.3.{" "}
                  {t(
                    "Consequently, you expressly grant WindEurope permission to use your personal data to accomplish the Purpose set out under article 3"
                  )}
                  .
                </p>
              </Box>
              <Box>
                <h3>5. {t("Personal Data")}</h3>
                <p>
                  5.1.{" "}
                  {t(
                    "When you use and access the Tool, the personal data WindEurope can process are"
                  )}
                  :
                </p>
                <ol>
                  <li>
                    {t(
                      "Account data / Login credentials: first name, name, business E-mail, (business) phone number"
                    )}
                    .
                  </li>
                </ol>
                <p>
                  5.2.{" "}
                  {t(
                    "With agreeing to this privacy policy, you guarantee that the personal data you have provided, are complete and correct"
                  )}
                  .
                </p>
              </Box>
              <Box>
                <h3>6. {t("Transfer of personal data to third parties")}</h3>
                <p>
                  6.1.{" "}
                  {t(
                    "WindEurope guarantees it does not transfer your personal data to third parties, unless"
                  )}
                  :
                </p>
                <ol>
                  <li>
                    {t(
                      "There is a legal obligation to transfer the personal data"
                    )}
                    ;
                  </li>
                  <li>
                    {t(
                      "To its partners (e.g., subcontractors, partners or contracting parties) for the achievement of the Purpose"
                    )}
                    ;
                  </li>
                  <li>
                    {t("WindEurope has a legitimate interest in doing so")}.
                  </li>
                </ol>
                <p>
                  6.2.{" "}
                  {t(
                    "Any third-party service providers and professional advisors to whom your personal data is disclosed, are expected and required to protect the confidentiality and security of your personal data and may only use your personal data in compliance with applicable data privacy laws"
                  )}
                  .
                </p>
              </Box>
              <Box>
                <h3>7. {t("Storage period")}</h3>
                <p>
                  7.1.{" "}
                  {t(
                    "WindEurope stores personal data for as long as is necessary to achieve the Purpose"
                  )}
                  .
                </p>
              </Box>
              <Box>
                <h3>8. {t("Your rights")}</h3>
                <p>
                  8.1.{" "}
                  {t(
                    "The Privacy Legislation provides you with a number of rights in relation to your personal data. You have the right, free of charge"
                  )}
                  :
                </p>
                <ol>
                  <li>
                    {t(
                      "to have access to and receive a copy of your personal data"
                    )}
                    ;
                  </li>
                  <li>{t("to have your personal data erased in case")}:</li>
                  <ol type="a">
                    <li>
                      {t(
                        "your personal data are no longer necessary to achieve the Purpose"
                      )}
                      ;
                    </li>
                    <li>
                      {t(
                        "you withdraw your consent and there is no other legal ground for the processing of the personal data"
                      )}
                      ;
                    </li>
                    <li>
                      {t(
                        "you object to the processing of the personal data and there is no other legal ground for the processing of the personal data"
                      )}
                      ;
                    </li>
                    <li>
                      {t("the personal data have been unlawfully processed")};
                    </li>
                    <li>
                      {t(
                        "there is a legal obligation to erase the personal data"
                      )}
                      ;
                    </li>
                  </ol>
                  <li>
                    {t(
                      "to have the processing of your personal data restricted"
                    )}
                    ;
                  </li>
                  <li>
                    {t(
                      "to have your personal data transferred to a third party"
                    )}
                    ;
                  </li>
                  <li>
                    {t(
                      "to withdraw your consent pursuant to which WindEurope is allowed to process your personal data"
                    )}
                    ;
                  </li>
                  <li>
                    {t(
                      "to lodge a complaint with the Commission for the Protection of Privacy (“Privacy Commission”) if you are of the opinion that the processing of your personal data breaches the Privacy Legislation"
                    )}
                    .
                  </li>
                </ol>
                <p>
                  8.2.{" "}
                  {t(
                    "In case you want to exercise the above rights, and provided you prove your identity, you can send a written, dated and signed request by mail to"
                  )}{" "}
                  <b>
                    {t(
                      "Eline Post, Director for Human Resource (HR) and Legal, Rue Belliard 40, 1040 Brussels, Belgium"
                    )}
                  </b>{" "}
                  {t("or by email to")}{" "}
                  <a href="mailto:privacy@windeurope.org">
                    privacy@windeurope.org
                  </a>
                  .
                </p>
              </Box>
              <Box>
                <h3>9. {t("Security")}</h3>
                <p>
                  9.1.{" "}
                  {t(
                    "WindEurope commits to take (or have) all reasonable measures (taken) to its best ability to safeguard the protection of personal data through technical safety measures and an appropriate safety policy for its employees. The personal data are stored with WindEurope, or, if applicable, with the processor of the personal data on servers located in Stockholm, Sweden. You acknowledge and accept that the transfer and storage of personal data is never without risk and consequently, the damage that you may suffer from the unlawful use of your personal data by third parties can never be collected from WindEurope"
                  )}
                  .
                </p>
              </Box>
              <Box>
                <h3>10. {t("Liability")}</h3>
                <p>
                  10.1.{" "}
                  {t(
                    "WindEurope can solely be held accountable for damages which directly result from the processing of your personal data due to a fault or negligence of WindEurope. In any event, WindEurope cannot be held liable: (i) in circumstances of force majeure and/or (ii) for any indirect or consequential damage and/or (iii) for damages that result from errors, faults or negligence by you or third parties. WindEurope's liability cannot exceed 1,000 euro (thousand euro) for breaches regarding this privacy policy and/or Privacy Legislation"
                  )}
                  .
                </p>
              </Box>
              <Box>
                <h3>11. {t("Severability")}</h3>
                <p>
                  11.1.{" "}
                  {t(
                    "If any provision in this privacy policy is deemed to be unlawful or unenforceable, that provision shall be amended by WindEurope and yourself insofar as necessary to make it lawful or enforceable, while retaining the meaning of WindEurope and yourself with respect to that provision as much as possible"
                  )}
                  .
                </p>
                <p>
                  {t(
                    "Whenever possible, the provisions of this privacy policy shall be interpreted in such a manner as to be valid and enforceable under the applicable law. However, if one or more provisions of this privacy policy are found to be invalid, illegal or unenforceable, in whole or in part, the remainder of that provision and of this privacy policy shall remain in full force and effect as if such invalid, illegal or unenforceable provision had never been contained herein"
                  )}
                  .
                </p>
              </Box>
              <Box>
                <h3>12. {t("Applicable law and Competent court")}</h3>
                <p>
                  12.1.{" "}
                  {t(
                    "You agree that all disputes between you and WindEurope regarding personal data and privacy issues, are exclusively subject to Belgian law, excluding any conflict of law principles"
                  )}
                  .
                </p>
                <p>
                  12.2.{" "}
                  {t(
                    "Every dispute regarding personal data and privacy issues belongs to the exclusive jurisdiction of the Dutch speaking courts of Brussels, Belgium, excluding any other court"
                  )}
                  .
                </p>
              </Box>
            </Container>
          </ContentLayout>
        }
      />
    </>
  );
}
