import { useState, useContext, useEffect } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { UserContext } from "../../UserContext";
import DataPrivacyPopup from "../../DataPrivacyPopup";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";
import "../../i18n";
import {
  primaryGreen,
  warningOrange,
  warningRed,
  primaryBlue,
  Divider,
  DividerThick,
  isDeadlinePassed,
  MapCodeToLabel,
} from "../../shared";
import { Storage, API, graphqlOperation } from "aws-amplify";
import * as queries from "../../graphql/queries";
import * as optimizedQueries from "../../graphql/optimizedQueries";
import * as mutations from "../../graphql/mutations";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  BreadcrumbGroup,
  Grid,
  Box,
  Modal,
  Button,
  Container,
  Tabs,
  FormField,
  Select,
  ExpandableSection,
  Textarea,
  FileUpload,
  DatePicker,
  Icon,
  Input,
  Multiselect,
  Popover,
  Link,
  StatusIndicator,
  Checkbox,
} from "@cloudscape-design/components";
import { Tooltip, IconButton } from "@mui/material";
import NavigationBar from "../NavigationBar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WarningIcon from "@mui/icons-material/Warning";
import EditIcon from "@mui/icons-material/Edit";
import SendIcon from "@mui/icons-material/Send";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ReplyIcon from "@mui/icons-material/Reply";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import LoopIcon from "@mui/icons-material/Loop";
import DeleteIcon from "@mui/icons-material/Delete";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

export default function ExpandedDocuments() {
  const { t } = useTranslation();
  const { id } = useParams(); // projectId in URL parameter
  const { userDetails } = useContext(UserContext);
  const [tasks, setTasks] = useState([]);
  var [projectDetails, setProjectDetails] = useState({});
  const [preplanningDocuments, setPreplanningDocuments] = useState([]);
  const [initialConsultationDocuments, setInitialConsultationDocuments] =
    useState([]);
  const [planningProcessDocuments, setPlanningProcessDocuments] = useState([]);
  const [documentVersions, setDocumentVersions] = useState([]);
  const [finalConsultationDocuments, setFinalConsultationDocuments] = useState(
    []
  );
  const [approvalDocuments, setApprovalDocuments] = useState([]);

  // Create new document modal
  const [loadingCircle, showLoadingCircle] = useState(false);
  const [documentName, setDocumentName] = useState("");
  // const [assigneeOptions, setAssigneeOptions] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [newDocumentModal, showNewDocumentModal] = useState(false);
  const [deadline, setDeadline] = useState("");
  const [documentMandatory, setDocumentMandatory] = useState(false);
  const [publishable, setPublishable] = useState(false);
  const [assignee, setAssignee] = useState({
    label: t("None assigned"),
    value: "",
  });
  const [stage, setStage] = useState({
    value: "",
    label: t("None selected"),
  });
  var [relatedTasks, setRelatedTasks] = useState([
    {
      value: "",
      label: t("Not related to any tasks"),
    },
  ]);

  const getProjectDetails = async () => {
    const res = await API.graphql(
      graphqlOperation(optimizedQueries.getProject, {
        id: id,
      })
    );
    if (res.data.getProject) {
      setProjectDetails((projectDetails = res.data.getProject));
      // save all project tasks
      if (projectDetails?.tasks?.items) setTasks(projectDetails?.tasks?.items);

      // Save documents according to their stage
      setPreplanningDocuments(
        projectDetails?.documents?.items.filter(
          (document) => document?.stage === "a_preplanning"
        )
      );
      setInitialConsultationDocuments(
        projectDetails?.documents?.items.filter(
          (document) => document?.stage === "b_initial_consultation"
        )
      );
      setPlanningProcessDocuments(
        projectDetails?.documents?.items.filter(
          (document) => document?.stage === "c_planning"
        )
      );
      setFinalConsultationDocuments(
        projectDetails?.documents?.items.filter(
          (document) => document?.stage === "d_final_consultation"
        )
      );
      setApprovalDocuments(
        projectDetails?.documents?.items.filter(
          (document) => document?.stage === "e_approval"
        )
      );
      // Download All Comments:
      let myAllComments = [];
      for (const doc of res.data.getProject.documents.items) {
        myAllComments = [...myAllComments, ...doc?.comments?.items];
      }
      setAllComments(
        myAllComments.map((c) => ({
          comment: c?.comment,
          document: c?.document?.name,
          version: new Date(c?.version).toLocaleString(),
          section: c?.section,
          page: c?.page,
          date: c?.createdAt,
          by: `${c.persona.family_name}, ${c.persona.given_name} <${c.persona.email}>`,
        }))
      );
    }
  };

  const assigneeOptions = projectDetails?.agents?.items
    ? [
        ...projectDetails?.agents?.items?.map((r) => r.persona),
        ...projectDetails?.developers?.items.map((r) => r.persona),
        ...projectDetails?.smes?.items.map((r) => r.persona),
        projectDetails?.mainAgent,
        projectDetails?.mainDeveloper,
      ]
    : [];

  const createDocument = async () => {
    const variables = {
      name: documentName,
      description: null,
      deadline: deadline,
      mandatory: documentMandatory,
      publishable,
      stage: stage.value,
      status: "a_upload",
      assigneeId: assignee?.value,
      reviewerId: userDetails.id,
      projectId: projectDetails?.id,
      owners: [assignee?.value, userDetails.id],
    };
    await API.graphql(
      graphqlOperation(mutations.createDocument, { input: variables })
    )
      .then(async (res) => {
        console.log("Successfully created new document!");
      })
      .catch((error) => console.error(error));
  };

  // List files from S3
  const getAllFilesFromS3 = async () => {
    await Storage.list(`projects/${projectDetails?.id}/`, {
      level: "public",
    })
      .then(async ({ results }) => {
        setDocumentVersions(
          results.sort((a, b) => {
            const nameA = a.key;
            const nameB = b.key;
            if (nameA < nameB) return 1;
            if (nameA > nameB) return -1;
            return 0;
          })
        );
      })
      .catch((err) => console.error(err));
  };

  const getDocumentName = (d) => d.key.split("/")[d.key.split("/").length - 1];
  const downloadBlob = (blob, filename) => {
    const url = URL.createObjectURL(blob);
    const a = document?.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };

  const downloadBulkDocument = async (d) => {
    const signedURL = await Storage.get(d.key, { download: true });
    downloadBlob(
      signedURL.Body,
      `${
        projectDetails?.name
      } - (${d.lastModified.toLocaleString()}) ${getDocumentName(d)}`
    );
  };

  const downloadDocument = async (d) => {
    const signedURL = await Storage.get(d.key);
    window.open(signedURL, "_blank");
  };

  const deleteDocument = async (d) => {
    await Storage.remove(d.key, { level: "public" });
    getAllFilesFromS3();
  };

  const downloadAllAttachments = () => {
    for (const documentVersion of documentVersions) {
      downloadBulkDocument(documentVersion);
    }
  };

  const DocumentCommentsCSVHeaders = [
    { label: t("Comment"), key: "comment" },
    { label: t("Date"), key: "date" },
    { label: t("Author"), key: "by" },
    { label: t("Version"), key: "version" },
    { label: t("Page"), key: "page" },
    { label: t("Section"), key: "section" },
  ];

  useEffect(() => {
    getProjectDetails();
    // listPersonas();
  }, []);

  useEffect(() => {
    getAllFilesFromS3();
  }, [projectDetails]);

  return (
    <Authenticator>
      {/* Data Privacy disclaimer popup  */}
      {userDetails.dateOfPrivacyConsent === null && <DataPrivacyPopup />}
      <NavigationBar />
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={
          <ContentLayout
            header={
              <SpaceBetween size="xl">
                <div />
                <BreadcrumbGroup
                  ariaLabel="Breadcrumbs"
                  items={[
                    { text: "Dashboard", href: "/dashboard" },
                    {
                      text: t("Project Dashboard"),
                      href: "/project/" + id,
                    },
                    {
                      text: t("Project Documents"),
                      href: "#",
                    },
                  ]}
                />
                <Header
                  variant="h1"
                  description={
                    t("Created on") +
                    " " +
                    new Date(projectDetails?.createdAt).toDateString() +
                    " " +
                    t("by") +
                    " " +
                    projectDetails?.mainDeveloper?.given_name +
                    " " +
                    projectDetails?.mainDeveloper?.family_name
                  }
                >
                  {projectDetails?.name +
                    " " +
                    t("by") +
                    " " +
                    projectDetails?.mainDeveloper?.domain}
                </Header>
                <div />
              </SpaceBetween>
            }
          >
            <Container
              fitHeight
              header={
                <Header
                  variant="h2"
                  actions={
                    !projectDetails?.name ? (
                      <>
                        <CircularProgress size={20} />
                        <span style={{ paddingLeft: 10 }}>
                          <b>{t("Fetching data")}...</b>
                        </span>
                      </>
                    ) : (
                      <SpaceBetween direction="horizontal" size="s">
                        <Button
                          iconAlign="left"
                          iconName="download"
                          disabled={!allComments.length}
                        >
                          <CSVLink
                            separator={";"}
                            style={{
                              textDecoration: "none",
                              color: !allComments.length ? "gray" : primaryBlue,
                            }}
                            filename={`${projectDetails?.name}-${t(
                              "Document Comments"
                            )}-${new Date().toLocaleDateString()}.csv`}
                            data={allComments}
                            headers={DocumentCommentsCSVHeaders}
                          >
                            {t("Download All Comments")}
                          </CSVLink>
                        </Button>
                        <Button
                          iconAlign="left"
                          iconName="download"
                          onClick={() => downloadAllAttachments()}
                        >
                          {t("Download All Attachments")}
                        </Button>
                        {
                          // Only Main Agents and other assigned agents can add new documents
                          (userDetails?.id === projectDetails?.mainAgentId ||
                            projectDetails?.agents?.items
                              .map((item) => item?.persona?.id)
                              .includes(userDetails?.id)) && (
                            <Button
                              variant="primary"
                              iconAlign="left"
                              iconName="add-plus"
                              onClick={() => {
                                showNewDocumentModal(true);
                              }}
                            >
                              {t("Add New Document")}
                            </Button>
                          )
                        }
                      </SpaceBetween>
                    )
                  }
                >
                  {t("All Project Documents")}
                </Header>
              }
            >
              <div style={{ height: 30 }} />
              <SpaceBetween size="m">
                <DisplayDocumentsByStage
                  title={t("Pre-planning")}
                  projectDetails={projectDetails}
                  _documents={preplanningDocuments}
                  assigneeOptions={assigneeOptions}
                  defaultExpanded={projectDetails?.stage === "a_preplanning"}
                  getProjectDetails={getProjectDetails}
                  getAllFilesFromS3={getAllFilesFromS3}
                  documentVersions={documentVersions}
                  downloadDocument={downloadDocument}
                  deleteDocument={deleteDocument}
                />

                <DisplayDocumentsByStage
                  title={t("Initial Public Consultation")}
                  projectDetails={projectDetails}
                  _documents={initialConsultationDocuments}
                  assigneeOptions={assigneeOptions}
                  defaultExpanded={
                    projectDetails?.stage === "b_initial_consultation"
                  }
                  getProjectDetails={getProjectDetails}
                  getAllFilesFromS3={getAllFilesFromS3}
                  documentVersions={documentVersions}
                  downloadDocument={downloadDocument}
                  deleteDocument={deleteDocument}
                />

                <DisplayDocumentsByStage
                  title={t("Planning Process")}
                  projectDetails={projectDetails}
                  _documents={planningProcessDocuments}
                  assigneeOptions={assigneeOptions}
                  defaultExpanded={projectDetails?.stage === "c_planning"}
                  getProjectDetails={getProjectDetails}
                  getAllFilesFromS3={getAllFilesFromS3}
                  documentVersions={documentVersions}
                  downloadDocument={downloadDocument}
                  deleteDocument={deleteDocument}
                />

                <DisplayDocumentsByStage
                  title={t("Final Public Consultation")}
                  projectDetails={projectDetails}
                  _documents={finalConsultationDocuments}
                  assigneeOptions={assigneeOptions}
                  defaultExpanded={
                    projectDetails?.stage === "d_final_consultation"
                  }
                  getProjectDetails={getProjectDetails}
                  getAllFilesFromS3={getAllFilesFromS3}
                  documentVersions={documentVersions}
                  downloadDocument={downloadDocument}
                  deleteDocument={deleteDocument}
                />

                <DisplayDocumentsByStage
                  title={t("Final Approval")}
                  projectDetails={projectDetails}
                  _documents={approvalDocuments}
                  assigneeOptions={assigneeOptions}
                  defaultExpanded={projectDetails?.stage === "e_approval"}
                  getProjectDetails={getProjectDetails}
                  getAllFilesFromS3={getAllFilesFromS3}
                  documentVersions={documentVersions}
                  downloadDocument={downloadDocument}
                  deleteDocument={deleteDocument}
                />
              </SpaceBetween>

              {/* -------------- ADD DOCUMENT MODAL -------------- */}
              <Modal
                onDismiss={() => showNewDocumentModal(false)}
                visible={newDocumentModal}
                closeAriaLabel="Close modal"
                header={t("Add New Document")}
                footer={
                  <>
                    {
                      // Show loading circle when user clicks Save Changes
                      loadingCircle && (
                        <Box float="left">
                          <StatusIndicator type="loading">
                            {t("Saving changes")}...
                          </StatusIndicator>
                        </Box>
                      )
                    }
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          variant="link"
                          onClick={() => showNewDocumentModal(false)}
                        >
                          {t("Cancel")}
                        </Button>
                        <Button
                          variant="primary"
                          disabled={
                            documentName === "" ||
                            deadline === "" ||
                            stage.value === "" ||
                            assignee?.value === ""
                          }
                          onClick={async () => {
                            showLoadingCircle(true);
                            await createDocument();
                            await getProjectDetails();
                            showLoadingCircle(false);
                            showNewDocumentModal(false);
                          }}
                        >
                          {t("Add New Document")}
                        </Button>
                      </SpaceBetween>
                    </Box>
                  </>
                }
              >
                <SpaceBetween direction="vertical" size="m">
                  <FormField
                    label={t("Document Name")}
                    description={t("Set a name for your document")}
                  >
                    <Input
                      type="text"
                      placeholder={t("Enter a name here...")}
                      value={documentName}
                      onChange={(event) => setDocumentName(event.detail.value)}
                    />
                  </FormField>
                  <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <FormField
                      label={t("Stage")}
                      description="Set a stage for the document"
                    >
                      <Select
                        selectedAriaLabel="document stage selected"
                        selectedOption={stage}
                        onChange={({ detail }) =>
                          setStage(detail.selectedOption)
                        }
                        options={[
                          {
                            value: "a_preplanning",
                            label: t("Pre-planning"),
                          },
                          {
                            value: "b_initial_consultation",
                            label: t("Intial Public Consultation"),
                          },
                          {
                            value: "c_planning",
                            label: t("Planning Process"),
                          },
                          {
                            value: "d_final_consultation",
                            label: t("Final Public Consultation"),
                          },
                          {
                            value: "e_approval",
                            label: t("Final Approval"),
                          },
                        ]}
                      />
                    </FormField>
                    <FormField
                      label={t("Due date")}
                      description={t("Set a deadline for your document")}
                      constraintText="YYYY/MM/DD"
                    >
                      <DatePicker
                        onChange={({ detail }) => setDeadline(detail.value)}
                        value={deadline || ""}
                        openCalendarAriaLabel={(selectedDate) =>
                          `selected date is ${selectedDate}`
                        }
                        placeholder="YYYY/MM/DD"
                        nextMonthAriaLabel="Next month"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                      />
                    </FormField>
                    <Checkbox
                      onChange={({ detail }) =>
                        setDocumentMandatory(detail.checked)
                      }
                      checked={documentMandatory}
                    >
                      {t("Document is Mandatory")}
                    </Checkbox>
                    {userDetails?.id === projectDetails?.mainAgentId && (
                      <Checkbox
                        onChange={({ detail }) =>
                          setPublishable(detail.checked)
                        }
                        checked={publishable}
                      >
                        {t(
                          "Publish document to the Community page when stage ends"
                        )}
                      </Checkbox>
                    )}
                  </Grid>
                  <FormField
                    label={t("Assigned to")}
                    description={t("Select a user to assign your document to")}
                  >
                    <Select
                      selectedAriaLabel="Assignee selected"
                      selectedOption={assignee}
                      onChange={({ detail }) =>
                        setAssignee(detail.selectedOption)
                      }
                      options={assigneeOptions?.map((assignee) => ({
                        value: assignee?.id,
                        label: `${assignee?.given_name} ${assignee?.family_name}`,
                      }))}
                    />
                  </FormField>
                  <FormField
                    label={t("Related Tasks")}
                    description="Select task(s) that belong to this document"
                  >
                    <Multiselect
                      placeholder={t("Choose tasks")}
                      selectedAriaLabel="document related tasks selected"
                      selectedOptions={relatedTasks}
                      onChange={({ detail }) =>
                        setRelatedTasks((relatedTasks = detail.selectedOptions))
                      }
                      options={tasks
                        ?.filter((t) => t.stage === stage.value)
                        .map((task) => ({
                          value: task.id,
                          label: task.title,
                        }))}
                    />
                  </FormField>
                </SpaceBetween>
              </Modal>
            </Container>
          </ContentLayout>
        }
      />
    </Authenticator>
  );
}

const DisplayDocumentsByStage = ({
  title,
  projectDetails,
  _documents,
  assigneeOptions,
  defaultExpanded,
  getProjectDetails,
  getAllFilesFromS3,
  documentVersions,
  downloadDocument,
  deleteDocument,
}) => {
  const unixTimestamp = Date.now();
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext);
  const [tasks, setTasks] = useState([]);
  const [savingText, showSavingText] = useState(false);
  var [selectedDocumentId, setSelectedDocumentId] = useState("");
  const [editDocumentModal, showEditDocumentModal] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [deadline, setDeadline] = useState("");
  const [documentMandatory, setDocumentMandatory] = useState(false);
  const [publishable, setPublishable] = useState(false);
  const [loadingCircle, showLoadingCircle] = useState(false);
  const [assignee, setAssignee] = useState({
    label: t("None assigned"),
    value: "",
  });
  const [stage, setStage] = useState({
    value: "",
    label: t("None selected"),
  });
  var [newRelatedTasks, setNewRelatedTasks] = useState([
    {
      value: "",
      label: t("Not related to any tasks"),
    },
  ]);

  // Sort documents
  const [sortingField, setSortingField] = useState("deadline");
  const [sortReverse, setSortReverse] = useState(false);
  let documents = _documents.sort((_a, _b) => {
    let a;
    let b;
    if (!_a[sortingField]) {
      return 1;
    } else {
      a = _a[sortingField];
    }
    if (!_b[sortingField]) {
      return -1;
    } else {
      b = _b[sortingField];
    }

    if (b.toString() > a.toString()) {
      return sortReverse ? -1 : 1;
    } else {
      return sortReverse ? 1 : -1;
    }
  });

  // Upload files to S3
  const uploadFiles = async (detail, documentId) => {
    setAttachments([]);
    for (var file of detail.value) {
      const filePath = `projects/${projectDetails?.id}/documents/${documentId}/${unixTimestamp}/${file.name}`;
      try {
        await Storage.put(filePath, file, {
          resumable: true,
          completeCallback: (event) => {
            console.log(`Successfully uploaded ${event.key}`);
            getAllFilesFromS3();
          },
          progressCallback: (progress) => {
            console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
          },
          errorCallback: (err) => {
            console.error("Unexpected error while uploading", err);
          },
        });
      } catch (error) {
        console.log("Error uploading file: ", error);
      }
    }
  };

  const getCurrentDocumentDetails = async (documentId) => {
    setSelectedDocumentId((selectedDocumentId = documentId));
    await API.graphql(
      graphqlOperation(queries.getDocument, {
        id: documentId,
      })
    )
      .then((res) => {
        let document = res.data.getDocument;
        if (document) {
          setDocumentName(document?.name);
          setDeadline(document?.deadline);
          setDocumentMandatory(document?.mandatory);
          setPublishable(document?.publishable);
          setStage({
            value: document?.stage,
            label:
              document?.stage === "a_planning"
                ? t("Pre-planning")
                : document?.stage === "b_initial_consultation"
                ? t("Intial Public Consultation")
                : document?.stage === "c_planning"
                ? t("Planning Process")
                : document?.stage === "d_final_consultation"
                ? t("Final Consultation")
                : t("Final Approval"),
          });
          // Save the newly added or removed tasks set by user
          setNewRelatedTasks(
            document?.tasks?.items?.map((task) => ({
              value: task.taskId,
              label: t(task.task.title),
            }))
          );
          setAssignee({
            value: document?.assignedTo?.id,
            label:
              // If names are undefined, show as "None selected"
              document?.assignedTo?.given_name &&
              document?.assignedTo?.family_name !== undefined
                ? document?.assignedTo?.given_name +
                  " " +
                  document?.assignedTo?.family_name
                : t("None selected"),
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const updateDocument = async () => {
    API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id: projectDetails.id,
          admins: [
            ...projectDetails?.admins?.filter((a) => a !== assignee.value),
            assignee.value,
          ],
        },
      })
    );
    const variables = {
      id: selectedDocumentId,
      name: documentName,
      description: null,
      deadline: deadline,
      mandatory: documentMandatory,
      stage: stage.value,
      assigneeId: assignee?.value,
      projectId: projectDetails?.id,
      publishable,
      owners: [
        ...projectDetails?.documents?.items
          .find((t) => t.id === selectedDocumentId)
          ?.owners?.filter((a) => a !== assignee?.value),
        assignee?.value,
      ],
    };

    await API.graphql(
      graphqlOperation(mutations.updateDocument, {
        input: variables,
      })
    )
      .then(async (res) => {
        // Get the newly selected tasks by comparing the current and new array
        const currentRelatedTasks = res.data.updateDocument.tasks?.items?.map(
          (relationship) => relationship.task.id
        );
        const targetRelatedTasks = newRelatedTasks.map((task) => task.value);
        const tasksToUnrelate = currentRelatedTasks.filter(
          (tid) => !targetRelatedTasks.includes(tid)
        );
        const tasksToRelate = targetRelatedTasks.filter(
          (tid) => !currentRelatedTasks.includes(tid)
        );
        // Add the newly selected tasks
        for (const taskId of tasksToRelate) {
          await API.graphql(
            graphqlOperation(mutations.createDocumentTasks, {
              input: {
                documentId: selectedDocumentId,
                taskId,
              },
            })
          );
        }

        // For unrelated tasks, remove the its document-tasks relationship
        const relationshipsToEnd = res.data.updateDocument.tasks?.items?.filter(
          (relationship) => tasksToUnrelate.includes(relationship.task.id)
        );
        for (const relationshipToEnd of relationshipsToEnd) {
          await API.graphql(
            graphqlOperation(mutations.deleteDocumentTasks, {
              input: { id: relationshipToEnd.id },
            })
          );
        }
        await getProjectDetails();
      })
      .catch((error) => console.error(error));
  };

  const updateTaskStatus = async (taskId, status) => {
    const now = new Date().toISOString();
    const notification_input = {
      id: projectDetails.id,
      notificationMessage: status ? "task_done" : null,
    };
    if (status) notification_input["nested_update_at"] = now;
    API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: notification_input,
      })
    );
    await API.graphql(
      graphqlOperation(mutations.updateTask, {
        input: {
          id: taskId,
          done: status,
          done_at: status ? now : null,
        },
      })
    )
      .then(async (res) => {
        await getProjectDetails();
      })
      .catch((error) => console.error(error));
  };

  const updateDocumentStatus = async (documentId, status) => {
    const now = new Date().toISOString();
    API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id: projectDetails?.id,
          nested_update_at: now,
          notificationMessage: status,
        },
      })
    );
    const input = {
      id: documentId,
      status: status,
    };
    input[`${status}_at`] = now;

    await API.graphql(
      graphqlOperation(mutations.updateDocument, {
        input,
      })
    )
      .then(async (res) => {
        await getProjectDetails();
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (projectDetails?.tasks?.items) setTasks(projectDetails?.tasks?.items);
  }, [documents]);

  const getDocumentName = (d) => d.key.split("/")[d.key.split("/").length - 1];

  const documentContent = (document) => {
    const documentSpecificVersions = documentVersions.filter(
      (documentVersion) => documentVersion.key.includes(document.id)
    );
    return (
      <div key={document?.id}>
        <Grid
          gridDefinition={[
            { colspan: 3 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 2 },
            { colspan: 1 },
          ]}
        >
          <div>
            <div style={{ display: "flex" }}>
              {isDeadlinePassed(document?.deadline) &&
              document?.status !== "d_done" ? (
                <WarningIcon sx={{ color: warningRed, marginRight: 1 }} />
              ) : (
                (document?.status === "a_upload" && (
                  <CloudUploadIcon
                    sx={{ color: primaryBlue, marginRight: 1 }}
                  />
                )) ||
                (document?.status === "b_review" && (
                  <PendingIcon sx={{ color: primaryBlue, marginRight: 1 }} />
                )) ||
                (document?.status === "c_revise" && (
                  <LoopIcon
                    sx={{
                      color: warningOrange,
                      marginRight: 1,
                    }}
                  />
                )) ||
                (document?.status === "d_done" && (
                  <CheckCircleIcon
                    sx={{
                      color: primaryGreen,
                      marginRight: 1,
                    }}
                  />
                ))
              )}
              <b>
                {t(document?.name)}{" "}
                {!document?.mandatory && `(${t("Optional")})`}
              </b>
            </div>
            {documentSpecificVersions.length > 0 && (
              <div style={{ display: "flex", marginTop: 20 }}>
                <Icon name="file" />
                <a
                  rel="noreferrer"
                  href="#"
                  download
                  onClick={(e) => {
                    e.preventDefault();
                    downloadDocument(documentSpecificVersions[0]);
                  }}
                  style={{ textDecoration: "none", marginLeft: 5 }}
                >
                  {getDocumentName(documentSpecificVersions[0])}
                  <br />
                  <span style={{ color: "gray" }}>
                    {documentSpecificVersions[0].lastModified.toLocaleString()},{" "}
                    <i>{documentSpecificVersions[0].size / 1000} kb</i>
                  </span>
                </a>
                <br />
              </div>
            )}
          </div>
          <div>
            {t("Linked to")} <b>{document?.tasks?.items.length}</b>{" "}
            {t(document?.tasks?.items.length > 1 ? "tasks" : "task")}:
            {document?.tasks?.items.map((item, index) => (
              <li style={{ paddingTop: 5, fontSize: "0.8em" }}>
                {t(item.task.title)}
              </li>
            ))}
          </div>
          <div>
            <span
              style={{
                fontWeight:
                  isDeadlinePassed(document?.deadline) &&
                  document?.status !== "d_done" &&
                  "bold",
                color:
                  isDeadlinePassed(document?.deadline) &&
                  document?.status !== "d_done" &&
                  warningOrange,
              }}
            >
              {document?.deadline || "-"}
              <br />
            </span>
          </div>
          <div>
            {document?.assignedTo?.given_name !== undefined &&
              document?.assignedTo?.family_name !== undefined && (
                <Popover
                  position="top"
                  dismissButton={false}
                  size="medium"
                  triggerType="custom"
                  header={
                    document?.assignedTo?.given_name +
                    " " +
                    document?.assignedTo?.family_name
                  }
                  content={
                    <SpaceBetween size="s">
                      <Link>{document?.assignedTo?.email}</Link>
                      <span>{document?.assignedTo?.phone_number}</span>
                      <span>
                        {document?.assignedTo?.role === "agents"
                          ? "Agent"
                          : "Developer"}
                      </span>
                      <span>
                        {t("Municipality")}:{" "}
                        {MapCodeToLabel(document?.assignedTo?.municipality) ||
                          "None"}
                      </span>
                    </SpaceBetween>
                  }
                >
                  <div style={{ display: "flex", cursor: "pointer" }}>
                    <AccountCircleIcon style={{ marginRight: 5 }} />
                    <b
                      style={{
                        color: primaryBlue,
                      }}
                    >
                      {document?.assignedTo?.given_name +
                        " " +
                        document?.assignedTo?.family_name}
                    </b>
                  </div>
                </Popover>
              )}
          </div>
          <div
            style={{
              textAlign: "center",
              color: "white",
              width: 120,
              height: "auto",
              verticalAlign: "middle",
              display: "table-cell",
              fontSize: 12,
              fontWeight: "bold",
              borderRadius: 5,
              padding: 3,
              background:
                (document?.status === "a_upload" && primaryBlue) ||
                (document?.status === "b_review" && "#B083E1") ||
                (document?.status === "c_revise" && warningOrange) ||
                (document?.status === "d_done" && primaryGreen),
            }}
          >
            {(document?.status === "a_upload" && t("Ready for upload")) ||
              (document?.status === "b_review" && t("Sent for review")) ||
              (document?.status === "c_revise" && t("Revision needed")) ||
              (document?.status === "d_done" && t("Completed")) ||
              "-"}
          </div>
          {!savingText && (
            <div>
              {
                // Display action buttons depending on document status
                // a_upload: assignedTo can Upload files and once a file has been uploaded, can Send to review (b_review)
                // b_review: reviewedBy can Send back to edit (c_revise) or Mark as complete (d_done)
                // c_revise: same as a_upload
              }
              <SpaceBetween direction="horizontal">
                {(document?.reviewerId === userDetails.id ||
                  document?.assigneeId === userDetails.id) && (
                  <Tooltip title={t("Edit Document")}>
                    <IconButton
                      aria-label={t("Edit Document")}
                      onClick={() => {
                        getCurrentDocumentDetails(document?.id);
                        showEditDocumentModal(true);
                      }}
                    >
                      <EditIcon style={{ color: primaryBlue }} />
                    </IconButton>
                  </Tooltip>
                )}
                {document?.assigneeId === userDetails.id &&
                  document?.reviewerId === userDetails.id && (
                    <>
                      {(documentSpecificVersions.length > 0 ||
                        !document?.mandatory) &&
                        document?.status !== "d_done" && (
                          <Tooltip title={t("Mark as complete")}>
                            <IconButton
                              aria-label={t("Mark as complete")}
                              onClick={async () => {
                                showSavingText(true);

                                await updateDocumentStatus(
                                  document?.id,
                                  "d_done"
                                );
                                await document?.tasks?.items
                                  ?.filter(
                                    (t) =>
                                      t.task.assigneeId ===
                                        document?.assigneeId && !t.task.done
                                  )
                                  ?.map((t) =>
                                    updateTaskStatus(t?.task?.id, true)
                                  );
                                showSavingText(false);
                              }}
                            >
                              <CheckCircleIcon style={{ color: primaryBlue }} />
                            </IconButton>
                          </Tooltip>
                        )}
                    </>
                  )}
                {
                  // assignedTo can Upload files
                  document?.assigneeId === userDetails.id &&
                    document?.assigneeId !== document?.reviewerId &&
                    (document?.status === "a_upload" ||
                      document?.status === "c_revise") && (
                      <>
                        {documentSpecificVersions.length > 0 && (
                          <Tooltip title="Send for review">
                            <IconButton
                              aria-label="Send for review"
                              onClick={async () => {
                                showSavingText(true);
                                await updateDocumentStatus(
                                  document?.id,
                                  "b_review"
                                );
                                await document?.tasks?.items
                                  ?.filter(
                                    (t) =>
                                      t.task.assigneeId ===
                                        document?.assigneeId && !t.task.done
                                  )
                                  ?.map((t) =>
                                    updateTaskStatus(t?.task?.id, true)
                                  );
                                showSavingText(false);
                              }}
                            >
                              <SendIcon style={{ color: primaryBlue }} />
                            </IconButton>
                          </Tooltip>
                        )}
                      </>
                    )
                }
                {
                  // reviewedBy can Send back to edit (c_revise) or Mark as complete (d_done)
                  document?.reviewerId === userDetails.id &&
                    document?.assigneeId !== document?.reviewerId &&
                    document?.status === "b_review" && (
                      <>
                        <Tooltip title="Send back for edit">
                          <IconButton
                            aria-label="Send back for edit"
                            onClick={async () => {
                              showSavingText(true);
                              await updateDocumentStatus(
                                document?.id,
                                "c_revise"
                              );
                              await document?.tasks?.items
                                ?.filter(
                                  (t) =>
                                    t.task.assigneeId ===
                                      document?.assigneeId && t.task.done
                                )
                                ?.map((t) =>
                                  updateTaskStatus(t?.task?.id, false)
                                );
                              showSavingText(false);
                            }}
                          >
                            <ReplyIcon style={{ color: primaryBlue }} />
                          </IconButton>
                        </Tooltip>
                        {(documentSpecificVersions.length > 0 ||
                          !document?.mandatory) &&
                          document?.status !== "d_done" && (
                            <Tooltip title={t("Mark as complete")}>
                              <IconButton
                                aria-label={t("Mark as complete")}
                                onClick={async () => {
                                  showSavingText(true);

                                  await updateDocumentStatus(
                                    document?.id,
                                    "d_done"
                                  );
                                  await document?.tasks?.items
                                    ?.filter(
                                      (t) =>
                                        t.task.assigneeId ===
                                          document?.assigneeId && !t.task.done
                                    )
                                    ?.map((t) =>
                                      updateTaskStatus(t?.task?.id, true)
                                    );
                                  showSavingText(false);
                                }}
                              >
                                <CheckCircleIcon
                                  style={{ color: primaryBlue }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                      </>
                    )
                }
                {document?.status === "d_done" &&
                  document?.reviewerId === userDetails.id && (
                    <Tooltip title={t("Re-Open Document")}>
                      <IconButton
                        aria-label={t("Re-Open Document")}
                        onClick={async () => {
                          showSavingText(true);

                          await updateDocumentStatus(
                            document?.id,
                            document?.assigneeId === userDetails.id
                              ? "a_upload"
                              : "c_revise"
                          );
                          await document?.tasks?.items
                            ?.filter(
                              (t) =>
                                t.task.assigneeId === document?.assigneeId &&
                                t.task.done
                            )
                            ?.map((t) => updateTaskStatus(t?.task?.id, false));
                          showSavingText(false);
                        }}
                      >
                        <AddCircleIcon style={{ color: warningOrange }} />
                      </IconButton>
                    </Tooltip>
                  )}
              </SpaceBetween>
            </div>
          )}
        </Grid>
        <div style={{ height: 15 }} />

        {/* ------------------ Upload files ------------------ */}
        {document?.assigneeId === userDetails.id &&
          ["a_upload", "c_revise"].includes(document?.status) && (
            <FormField>
              <FileUpload
                tokenLimit={3}
                onChange={({ detail }) => uploadFiles(detail, document?.id)}
                value={attachments}
                i18nStrings={{
                  uploadButtonText: (e) => t("Upload files"),
                  dropzoneText: (e) => t("Drop files to upload"),
                  removeFileAriaLabel: (e) => `Remove file ${e + 1}`,
                  limitShowFewer: "Show fewer files",
                  limitShowMore: "Show more files",
                  errorIconAriaLabel: "Error",
                }}
              />
            </FormField>
          )}

        <div style={{ height: 15 }} />
        <DisplayComments
          getDocumentName={getDocumentName}
          CSVLink={CSVLink}
          downloadDocument={downloadDocument}
          deleteDocument={deleteDocument}
          documentSpecificVersions={documentSpecificVersions}
          documentId={document?.id}
          documentName={document?.name}
          document={document}
          projectDetails={projectDetails}
          getProjectDetails={getProjectDetails}
          t={t}
        />
        <Divider />
      </div>
    );
  };

  return (
    <ExpandableSection
      defaultExpanded={defaultExpanded}
      variant="container"
      headerText={title}
    >
      {!documents.length ? (
        <i style={{ color: "gray" }}>{t("No documents available")}.</i>
      ) : (
        <>
          <Box float="right">
            {savingText && (
              <StatusIndicator type="loading">
                {t("Saving changes")}...
              </StatusIndicator>
            )}
          </Box>
          <br />
          <br />
          <Grid
            gridDefinition={[
              { colspan: 3 },
              { colspan: 2 },
              { colspan: 2 },
              { colspan: 2 },
              { colspan: 2 },
              { colspan: 1 },
            ]}
          >
            <div>
              <b>{t("Document Name")}</b>
            </div>
            <div>
              <b>{t("Linked Tasks")}</b>
            </div>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <b
                onClick={() =>
                  sortingField === "deadline"
                    ? setSortReverse(!sortReverse)
                    : setSortingField("deadline")
                }
              >
                {t("Due Date")}
              </b>
              <Icon
                name="upload-download"
                variant={sortingField === "deadline" ? "link" : "subtle"}
              />
            </div>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <b
                style={{ marginRight: 7 }}
                onClick={() =>
                  sortingField === "assigneeId"
                    ? setSortReverse(!sortReverse)
                    : setSortingField("assigneeId")
                }
              >
                {t("Assigned to")}
              </b>
              <Icon
                name="upload-download"
                variant={sortingField === "assigneeId" ? "link" : "subtle"}
              />
            </div>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <b
                style={{ marginRight: 7 }}
                onClick={() =>
                  sortingField === "status"
                    ? setSortReverse(!sortReverse)
                    : setSortingField("status")
                }
              >
                {t("Status")}
              </b>
              <Icon
                name="upload-download"
                variant={sortingField === "status" ? "link" : "subtle"}
              />
            </div>
            <div>
              <b>{t("Actions")}</b>
            </div>
          </Grid>
          <DividerThick />

          {!documents ? (
            <p>{t("Loading")}...</p>
          ) : (
            <>
              {documents.map((document) => documentContent(document))}

              {/* --------------------- EDIT DOCUMENT MODAL -------------------------v*/}
              <Modal
                onDismiss={() => showEditDocumentModal(false)}
                visible={editDocumentModal}
                closeAriaLabel="Close modal"
                header={t("Edit Document")}
                footer={
                  <>
                    {
                      // Show fetching data status every time modal opens
                      !documentName && (
                        <Box float="left">
                          <StatusIndicator type="loading">
                            {t("Fetching data")}...
                          </StatusIndicator>
                        </Box>
                      )
                    }
                    {
                      // Show loading circle when user clicks Save Changes
                      loadingCircle && (
                        <Box float="left">
                          <StatusIndicator type="loading">
                            {t("Saving changes")}...
                          </StatusIndicator>
                        </Box>
                      )
                    }
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          variant="link"
                          onClick={() => showEditDocumentModal(false)}
                        >
                          {t("Cancel")}
                        </Button>
                        <Button
                          variant="primary"
                          onClick={async () => {
                            showLoadingCircle(true);
                            await updateDocument();
                            showLoadingCircle(false);
                            showEditDocumentModal(false);
                          }}
                        >
                          {t("Save Changes")}
                        </Button>
                      </SpaceBetween>
                    </Box>
                  </>
                }
              >
                <SpaceBetween direction="vertical" size="m">
                  <FormField
                    label={t("Document Name")}
                    description={t("Set a name for your document")}
                  >
                    <Input
                      placeholder={t("Enter a name here...")}
                      value={t(documentName)}
                      onChange={(event) => setDocumentName(event.detail.value)}
                    />
                  </FormField>
                  <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <FormField
                      label={t("Stage")}
                      description={t("Set a stage for the document")}
                    >
                      <Select
                        selectedAriaLabel="document stage selected"
                        selectedOption={stage}
                        onChange={({ detail }) =>
                          setStage(detail.selectedOption)
                        }
                        options={[
                          {
                            value: "a_preplanning",
                            label: t("Pre-planning"),
                          },
                          {
                            value: "b_initial_consultation",
                            label: t("Intial Public Consultation"),
                          },
                          {
                            value: "c_planning",
                            label: t("Planning Process"),
                          },
                          {
                            value: "d_final_consultation",
                            label: t("Final Consultation"),
                          },
                          {
                            value: "e_approval",
                            label: t("Final Approval"),
                          },
                        ]}
                      />
                    </FormField>
                    <FormField
                      label={t("Due date")}
                      description={t("Set a deadline for your document")}
                      constraintText="YYYY/MM/DD"
                    >
                      <DatePicker
                        onChange={({ detail }) => setDeadline(detail.value)}
                        value={deadline || ""}
                        openCalendarAriaLabel={(selectedDate) =>
                          `selected date is ${selectedDate}`
                        }
                        placeholder="YYYY/MM/DD"
                        nextMonthAriaLabel="Next month"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                      />
                    </FormField>
                    <Checkbox
                      onChange={({ detail }) =>
                        setDocumentMandatory(detail.checked)
                      }
                      checked={documentMandatory}
                    >
                      {t("Document is Mandatory")}
                    </Checkbox>
                    {userDetails?.id === projectDetails?.mainAgentId && (
                      <Checkbox
                        onChange={({ detail }) =>
                          setPublishable(detail.checked)
                        }
                        checked={publishable}
                      >
                        {t(
                          "Publish document to the Community page when stage ends"
                        )}
                      </Checkbox>
                    )}
                  </Grid>
                  <FormField
                    label={t("Assigned to")}
                    description={t("Select a user to assign your document to")}
                  >
                    <Select
                      selectedAriaLabel="Assignee selected"
                      selectedOption={assignee}
                      onChange={({ detail }) =>
                        setAssignee(detail.selectedOption)
                      }
                      options={assigneeOptions.map((assignee) => ({
                        value: assignee?.id,
                        label: `${assignee?.given_name} ${assignee?.family_name}`,
                      }))}
                    />
                  </FormField>
                  <FormField
                    label={t("Related Tasks")}
                    description={t(
                      "Select task(s) that belong to this document"
                    )}
                  >
                    <Multiselect
                      placeholder={t("Choose tasks")}
                      selectedAriaLabel="related tasks selected"
                      selectedOptions={newRelatedTasks}
                      onChange={({ detail }) => {
                        setNewRelatedTasks(detail.selectedOptions);
                      }}
                      options={tasks
                        ?.filter((t) => t.stage === stage.value)
                        .map((task) => ({
                          value: task.id,
                          label: t(task.title),
                        }))}
                    />
                  </FormField>
                </SpaceBetween>
              </Modal>
            </>
          )}
        </>
      )}
    </ExpandableSection>
  );
};

const DisplayComments = ({
  documentId,
  documentName,
  documentSpecificVersions,
  downloadDocument,
  deleteDocument,
  getDocumentName,
  projectDetails,
  t,
  CSVLink,
  getProjectDetails,
  document,
}) => {
  const { userDetails } = useContext(UserContext);
  const [chapter, setChapter] = useState(null);
  const [pageNumber, setPageNumber] = useState(null);
  const [comment, setComment] = useState("");
  const [loadingCircle, showLoadingCircle] = useState(false);

  const existingComments = projectDetails?.documents?.items?.find(
    (d) => d.id === documentId
  )?.comments.items;

  const submitComment = async () => {
    const now = new Date().toISOString();
    API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id: projectDetails?.id,
          nested_update_at: now,
          notificationMessage: "document_comment",
        },
      })
    );

    await API.graphql(
      graphqlOperation(mutations.createDocumentComment, {
        input: {
          version: documentSpecificVersions[0].lastModified.toISOString(),
          documentId: documentId,
          comment: comment,
          page: pageNumber,
          section: chapter,
          personaId: userDetails.id,
          owner: userDetails.id,
        },
      })
    )
      .then(async (res) => {
        showLoadingCircle(true);
        await getProjectDetails();
        showLoadingCircle(false);
      })
      .catch((error) => console.error(error));
  };

  const deleteComment = async (comment) => {
    await API.graphql(
      graphqlOperation(mutations.deleteDocumentComment, {
        input: {
          id: comment.id,
        },
      })
    )
      .then(async (res) => {
        showLoadingCircle(true);
        await getProjectDetails();
        showLoadingCircle(false);
      })
      .catch((error) => console.error(error));
  };

  const CommentsHeaders = [
    { label: t("Comment"), key: "comment" },
    { label: t("Date"), key: "date" },
    { label: t("Author"), key: "by" },
    { label: t("Version"), key: "version" },
    { label: t("Page"), key: "page" },
    { label: t("Section"), key: "section" },
  ];

  const [deleteCommentModal, showDeleteCommentModal] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);
  const [deleteVersionModal, showDeleteVersionModal] = useState(false);
  const [versionToDelete, setVersionToDelete] = useState(null);

  return (
    <ExpandableSection
      headerText={
        <span style={{ fontSize: "0.8em" }}>
          {t("Comments")} (
          {existingComments?.length ? existingComments?.length : 0}) |{" "}
          {t("Versions")} ({documentSpecificVersions.length})
        </span>
      }
    >
      <Tabs
        tabs={[
          {
            label: <span style={{ fontSize: "0.8em" }}>{t("Comments")}</span>,
            id: "comments",
            content: (
              <>
                <FormField stretch label={t("Add a comment")}>
                  <SpaceBetween direction="horizontal" size="s">
                    <FormField
                      label={
                        <span
                          style={{
                            fontWeight: "normal",
                            fontSize: "0.9em",
                            color: "gray",
                          }}
                        >
                          {t("Chapter")} ({t("optional")})
                        </span>
                      }
                    >
                      <Input
                        value={chapter}
                        onChange={(event) => setChapter(event.detail.value)}
                      />
                    </FormField>
                    <FormField
                      label={
                        <span
                          style={{
                            fontWeight: "normal",
                            fontSize: "0.9em",
                            color: "gray",
                          }}
                        >
                          {t("Page Number")} ({t("optional")})
                        </span>
                      }
                    >
                      <Input
                        value={pageNumber}
                        onChange={(event) => setPageNumber(event.detail.value)}
                      />
                    </FormField>
                  </SpaceBetween>

                  <br />
                  <Textarea
                    rows={2}
                    ariaLabel="project comments"
                    placeholder={t("Enter a comment here...")}
                    onChange={({ detail }) => setComment(detail.value)}
                    value={comment}
                  />
                </FormField>
                <br />
                <Box float="left">
                  <Button
                    iconAlign="left"
                    iconName="download"
                    disabled={!existingComments.length}
                  >
                    <CSVLink
                      separator={";"}
                      style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        color: !existingComments.length ? "gray" : primaryBlue,
                      }}
                      filename={`${projectDetails?.name}-${t(documentName)}-${t(
                        "Comments"
                      )}-${new Date().toLocaleDateString()}.csv`}
                      data={existingComments?.map((c) => ({
                        comment: c.comment,
                        date: c.createdAt,
                        version: new Date(c.version).toLocaleString(),
                        page: c.page,
                        section: c.section,
                        by: `${c.persona.family_name}, ${c.persona.given_name} <${c.persona.email}>`,
                      }))}
                      headers={CommentsHeaders}
                    >
                      {t("Download Comments")}
                    </CSVLink>
                  </Button>
                </Box>
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="s">
                    {loadingCircle && (
                      <StatusIndicator type="loading">
                        {t("Saving changes")}...
                      </StatusIndicator>
                    )}
                    <Button
                      variant="primary"
                      disabled={!comment || !documentSpecificVersions.length}
                      onClick={async () => {
                        await submitComment();
                        setComment("");
                        setPageNumber(null);
                        setChapter(null);
                      }}
                    >
                      {t("Add comment")}
                    </Button>
                  </SpaceBetween>
                </Box>
                <div style={{ height: 70 }} />
                <Box>
                  {!existingComments.length ? (
                    <i style={{ color: "gray" }}>
                      {t("No comments available")}.
                    </i>
                  ) : (
                    existingComments.map((comment) => (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          paddingBottom: 20,
                        }}
                      >
                        <div style={{ float: "left" }}>
                          <div style={{ display: "flex" }}>
                            <AccountCircleIcon style={{ marginRight: 5 }} />
                            <b>
                              {comment.persona?.given_name +
                                " " +
                                comment.persona?.family_name}
                            </b>
                            <i style={{ paddingLeft: 10, color: "gray" }}>
                              on {new Date(comment.createdAt).toString()}
                            </i>
                          </div>
                          {comment?.version !== 0 &&
                            comment.version !== "0" && (
                              <div style={{ display: "flex" }}>
                                {comment.section && (
                                  <span>Chapter: {comment.section}</span>
                                )}
                                {comment.page && (
                                  <span style={{ marginLeft: 15 }}>
                                    Page: {comment.page}
                                  </span>
                                )}
                              </div>
                            )}
                          <p>{comment.comment}</p>
                          {comment?.version !== 0 && (
                            <i style={{ fontSize: "0.85em", color: "gray" }}>
                              Comment added on version{" "}
                              {new Date(comment.version).toLocaleString()}
                            </i>
                          )}
                        </div>
                        <div style={{ float: "right" }}>
                          {comment?.personaId === userDetails?.id && (
                            <Tooltip title={t("Delete comment")}>
                              <IconButton
                                aria-label={t("Delete comment")}
                                size="small"
                                onClick={() => {
                                  setCommentToDelete(comment);
                                  showDeleteCommentModal(true);
                                }}
                              >
                                <DeleteIcon
                                  sx={{
                                    fontSize: 20,
                                    color: "gray",
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    ))
                  )}
                </Box>
              </>
            ),
          },
          {
            label: (
              <span style={{ fontSize: "0.8em" }}>{t("Version History")}</span>
            ),
            id: "versionHistory",
            content: !documentSpecificVersions.length ? (
              <i style={{ color: "gray" }}>{t("No versions available")}.</i>
            ) : (
              documentSpecificVersions.map((documentVersion, index) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    paddingBottom: 20,
                  }}
                >
                  <div>
                    <span>
                      {/* <Icon name="file" />{" "} */}
                      <Link
                        onFollow={(e) => {
                          e.preventDefault();
                          downloadDocument(documentVersion);
                        }}
                      >
                        {getDocumentName(documentVersion)}
                      </Link>
                    </span>
                    <span
                      style={{
                        fontSize: "0.8em",
                        color: "gray",
                        marginTop: 15,
                        marginLeft: 7,
                        marginRight: 5,
                      }}
                    >
                      ({documentVersion.size / 1000} kb)
                    </span>
                    <span
                      style={{
                        fontSize: "0.8em",
                        color: "gray",
                        marginTop: 15,
                      }}
                    >
                      Uploaded on{" "}
                      {documentVersion.lastModified.toLocaleString()}
                    </span>
                  </div>
                  {userDetails?.id === document.assigneeId &&
                    document.status === "a_upload" && (
                      <div style={{ float: "right" }}>
                        <Tooltip title={t("Delete version")}>
                          <IconButton
                            aria-label={t("Delete version")}
                            size="small"
                            onClick={(e) => {
                              setVersionToDelete(documentVersion);
                              showDeleteVersionModal(true);
                            }}
                          >
                            <DeleteIcon
                              sx={{
                                fontSize: 17,
                                color: "gray",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                      </div>
                    )}
                </div>
              ))
            ),
          },
        ]}
      />

      {/* ------ Delete comment confirmation popup ------ */}
      <Modal
        onDismiss={() => showDeleteCommentModal(false)}
        visible={deleteCommentModal}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link">{t("Cancel")}</Button>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  deleteComment(commentToDelete);
                  showDeleteCommentModal(false);
                }}
              >
                {t("Delete comment")}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={t("Confirm action")}
      >
        {t("Are you sure you want to delete?")}
      </Modal>

      {/* ------ Delete version confirmation popup ------ */}
      <Modal
        onDismiss={() => showDeleteVersionModal(false)}
        visible={deleteVersionModal}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link">{t("Cancel")}</Button>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  deleteComment(versionToDelete);
                  showDeleteVersionModal(false);
                }}
              >
                {t("Delete version")}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={t("Confirm action")}
      >
        {t("Are you sure you want to delete?")}
      </Modal>
    </ExpandableSection>
  );
};
