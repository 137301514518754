/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_appsync_graphqlEndpoint": "https://pre7e2frkng7dcqqrv6hank7jm.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-north-1:d6d59cc5-65ec-4e55-8312-3ce4f828b642",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_8JeNDk13X",
    "aws_user_pools_web_client_id": "59t7mnu2ktvd7ve9s9615ndotj",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ezpermitsdocstorage112923-staging",
    "aws_user_files_s3_bucket_region": "eu-north-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-north-1",
            "maps": {
                "items": {
                    "ezpermitsmap-staging": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "ezpermitsmap-staging"
            },
            "search_indices": {
                "items": [
                    "ezpermitsplaceindex-staging"
                ],
                "default": "ezpermitsplaceindex-staging"
            }
        }
    }
};


export default awsmobile;
