import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as queries from "./graphql/queries";
import awsconfig from "./aws-exports";
import { API, Auth, Amplify, graphqlOperation } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { UserContext } from "./UserContext";
// Components
import SignIn from "./SignIn";
import PageNotFound from "./PageNotFound";
import PrivateRoute from "./PrivateRoute";
import LandingPage from "./components/community/LandingPage";
import WindfarmLocationsMap from "./components/community/WindfarmLocationsMap";
import FAQs from "./components/community/FAQs";
import DataPrivacy from "./components/DataPrivacy";
import Support from "./components/Support";
import ProjectSetup from "./components/ProjectSetup";
import Dashboard from "./components/dashboard/Dashboard";
import WorkflowSetup from "./components/project-dashboard/WorkflowSetup";
import ProjectDashboard from "./components/project-dashboard/ProjectDashboard";
import ExpandedDocuments from "./components/project-dashboard/ExpandedDocuments";
import ExpandedTasks from "./components/project-dashboard/ExpandedTasks";

Amplify.configure(awsconfig);

function App() {
  const [userDetails, setUserDetails] = useState({});
  const { authStatus } = useAuthenticator((context) => [context.route]);

  const getUser = async () => {
    const { attributes } = await Auth.currentAuthenticatedUser(); // get current auth user ID
    const variables = {
      filter: {
        id: {
          eq: attributes.sub,
        },
      },
    };
    let res = await API.graphql(
      graphqlOperation(queries.listPersonas, variables)
    );
    if (res.data.listPersonas.items?.length) {
      setUserDetails(res.data.listPersonas.items[0]);
    }
  };

  useEffect(() => {
    if (authStatus === "authenticated") getUser();
  }, [authStatus]);

  return (
    <UserContext.Provider value={{ userDetails }}>
      <Router>
        <Routes>
          {/* --------------------- PUBLIC ROUTES -------------------- */}
          <Route path="/" element={<LandingPage />} />
          <Route path="/signin" element={<SignIn />} />
          <Route path="/map" element={<WindfarmLocationsMap />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/data-privacy" element={<DataPrivacy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/404" element={<PageNotFound />} />
          <Route path="/*" element={<PageNotFound />} />
          {/* --------------------- PRIVATE ROUTES ------------------- */}
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/workflow-setup/:id"
            element={
              <PrivateRoute>
                <WorkflowSetup />
              </PrivateRoute>
            }
          />
          <Route
            path="/project/:id"
            element={
              <PrivateRoute>
                <ProjectDashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/project-setup"
            element={
              <PrivateRoute>
                <ProjectSetup />
              </PrivateRoute>
            }
          />
          <Route
            path="/project/:id/documents"
            element={
              <PrivateRoute>
                <ExpandedDocuments />
              </PrivateRoute>
            }
          />
          <Route
            path="/project/:id/tasks"
            element={
              <PrivateRoute>
                <ExpandedTasks />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </UserContext.Provider>
  );
}

export default App;
